import divider from '../../../images/how_it_works_divider.svg';
import DividerWithHeading from '../../DividerWithHeading';
import ReactPlayer from 'react-player';

export default function InformationalVideo() {
  return (
    <section className='mx-auto mt-16 max-w-8xl px-4 sm:px-6 lg:mt-32 lg:px-8'>
      <DividerWithHeading
        image={divider}
        heading={'How Does it Work?'}
        paragraph={'Take a detailed look into our data, products and more!'}
      />
      <div
        className={'mt-8 flex flex-col items-center justify-center sm:mt-10'}
      >
        <div className={'aspect-video w-full max-w-4xl'}>
          <ReactPlayer
            width={'100%'}
            height={'100%'}
            url='https://www.youtube.com/watch?v=-3HxV9hreVU'
            controls={true}
            playing={true}
            loop={true}
            muted={true}
            config={{
              youtube: {
                playerVars: { origin: 'http://localhost:3000' },
              },
            }}
            onError={(e) => console.log('onError', e)}
          />
        </div>
      </div>
    </section>
  );
}
