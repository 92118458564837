import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import PageTopper from '../../components/sections/Services/PageTopper';
import image from '../../images/service_cards/lighthouse_card.png';
import Identify from '../../components/sections/Services/Lighthouse/Identify';
import Intervene from '../../components/sections/Services/Lighthouse/Intervene';
import MonitorProgress from '../../components/sections/Services/Lighthouse/MonitorProgress';
import GrowthAndSuccess from '../../components/sections/Services/Lighthouse/GrowthAndSuccess';

export default function Lighthouse() {
  return (
    <main>
      <NavBar />
      <PageTopper
        heading={'A Complete MTSS'}
        headingSpan={'Management System'}
        paragraph={`Lighthouse is the most advanced MTSS management system available, and it’s designed to wrap around your existing supports and processes.`}
        paragraphTwo={`From the identification of students through evaluation, and all stages in between, your school can continue to improve its ability to have a big impact on the lives of students.`}
        image={image}
        imageAlt={'Lighthouse Logo'}
      />
      <Identify />
      <Intervene />
      <MonitorProgress />
      <GrowthAndSuccess />
      <Footer />
    </main>
  );
}
