import PostAuthor from './PostAuthor';
import authorImage from '../../../images/dave.webp';

export default function PostOne() {
  return (
    <div className='relative mt-16 overflow-hidden bg-white px-2 sm:px-6 lg:mt-32 lg:px-8'>
      <div className='hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full'>
        <div
          className='relative mx-auto h-full max-w-prose text-lg'
          aria-hidden='true'
        >
          <svg
            className='absolute top-12 left-full translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='74b3fd99-0a6f-4271-bef2-e80eeafdf357'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)'
            />
          </svg>
          <svg
            className='absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='f210dbf6-a58d-4871-961e-36d5016a0f49'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#f210dbf6-a58d-4871-961e-36d5016a0f49)'
            />
          </svg>
          <svg
            className='absolute bottom-12 left-full translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='d3eb07ae-5182-43e6-857d-35c643af9034'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#d3eb07ae-5182-43e6-857d-35c643af9034)'
            />
          </svg>
        </div>
      </div>
      <div className='relative px-4 sm:px-6 lg:px-8'>
        <div className='mx-auto max-w-prose text-lg'>
          <h1 className='text-center text-3xl font-bold tracking-tight text-brandText lg:text-4xl xl:text-5xl'>
            Is Your <span className='text-brandPrimary'>MTSS</span> Problem
            Really a <span className='text-brandPrimary'>Data</span> Problem?
          </h1>
          <p className='mt-8 text-xl leading-8 text-brandText'>
            Now more than ever, schools are inundated with struggling students.
            From learning loss, to social emotional disorders, and more, MTSS
            teams are finding themselves increasingly overwhelmed by the task at
            hand.
          </p>
        </div>
        <div className='prose prose-lg prose-indigo mx-auto mt-6 text-brandText'>
          <p>
            Many organizations have to consider the extent to which their MTSS
            problems are actually data problems-the wrong information in the
            wrong place at the wrong time. For many schools, it's the skeleton
            in the closest-leadership and student services teams don't really
            know if they…
          </p>
          <ul>
            <li>Found all the students in need.</li>
            <li>Identified the concern and needed treatment</li>
            <li>Prioritized the severity of students' needs appropriately</li>
            <li>Actually were able to administer the treatment</li>
            <li>
              Were ultimately successful in positively impacting the student's
              outcome
            </li>
          </ul>
          <p>
            Although many educators would be embarrassed to admit this is often
            the case, it should not come as a surprise. Disorganization of this
            sort of information is natural-and not always even for bad reasons.
          </p>
          <p>
            First and foremost, a school or district is a complex place with
            many functions. People are specialized. Some are silo'''d in
            classrooms and offices, while others may travel around the district
            but call no place “home.” School employees can be found doing vastly
            different jobs. Specialized work leads to specialized data
            collection. Try as they may, software vendors are typically
            unsuccessful in making a tool that does it all-as the saying goes, a
            jack of all trades is an Ace of none.
          </p>
          <p>
            Answering the real questions an MTSS director or school leader has
            about the progress being made almost always requires data from
            multiple sources. You want to look at grades, but also cross
            reference them with the test scores from a different vendor than the
            gradebook. You need to justify your program expenditures, but none
            of that data is connected to academic info.
          </p>
          <p>
            Thus begins the vicious cycle of data confusion. Numerous people
            take on the task of trying to create and curate data sets. The
            resident Excel or Google Sheets expert starts cooking up VLOOKUPS
            for just about everything. An intricate layer of spreadsheets,
            forms, and exports starts to develop, but it's really only begun.
            It's never possible to manage all these items and keep them up to
            date. Data entry errors, to new data formats, mismatched keys, and
            problems with data collection abound. There's always one person who
            “didn't get their data in” on time, and the process has to be
            redone. These are just some of the ways data becomes unclear.
            Equally important though, is the increasing amount of time being
            spent, up to 80% of the time available, just collecting and cleaning
            data.
          </p>
          <p>
            Now there are dueling reports. Different people show up with
            different numbers that speak to the same or similar items. Sometimes
            they are off by just enough to call into question which, if either,
            is accurate. Conversations are had, points are made, but it's hard
            to marshall the data enough to take action as a school. Data is
            presented and discussed, but it's not able to drive. People lose
            faith in that part of the process, and eventually, we're back at
            square one-feeling our way through the dark, not really able to
            coordinate as a team, working hard, never really having a sense of
            control over how we are helping students or what to do next. The
            naysays wonder what you, 'really do around here.'
          </p>
          <p>
            Working our way out of that is a lot less complicated than it might
            first appear. The fundamental starting point is recognizing how the
            data collection and analysis process can really break down and
            impact the staff members who work with students. This is a key loss
            for the organization: people who were hired due to their extensive
            training in student services are working collecting the data they
            need to do the job.
          </p>
          <p>
            Schools and districts can begin first by recognizing that there is a
            need for different “operational data systems.” If your staff is
            using certain software, web sites, curricula, programs, etc…it's
            likely because they found them useful. It's preferable to let them
            use what they chose where possible, so they are able to be most
            effective. But this does not mean that we have to live with the
            chaos of the vicious cycle.
          </p>
          <p>
            Between the operational data systems and your data stakeholders
            should be data pipeline, warehouse, and reporting system. This isn't
            rocket science-it-s exactly the thing everybody is trying to create
            when all of the problems above occur. If you're in a district with a
            very strong and well-resourced technology team with a variety of IT
            and instructional experience, they may be able to devote a
            significant portion of their staff's time to helping with moving
            from data collection systems to high quality analytics.
          </p>
          <p>
            Fortunately, there are now also other tools available for schools
            that allow them to almost completely delegate the data gruntwork to
            a dynamic web application, so their staff members can use real time,
            high quality data while spending their time helping students, not
            trying trying to piece together a puzzle with the answers on it.
          </p>
          <PostAuthor
            image={authorImage}
            name={'David Kush'}
            title={'Managing Director'}
          />
        </div>
      </div>
    </div>
  );
}
