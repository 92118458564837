import image from '../../../../images/service_pages/lighthouse/intervene.webp';
import dividerDesktop from '../../../../images/dividers/lighthouse_divider_desktop.svg';
import dividerMobile from '../../../../images/dividers/lighthouse_divider_mobile.svg';

export default function ImageRightTextLeft() {
  return (
    <section className={'mx-auto bg-white py-20 lg:py-32'}>
      <div className='mx-auto px-4 sm:px-6 lg:max-w-8xl lg:px-8'>
        <div className='mx-auto max-w-8xl md:hidden'>
          <img src={dividerMobile} alt='Divider with Lighthouse logo' />
        </div>
        <div className='mx-auto hidden max-w-8xl md:block'>
          <img src={dividerDesktop} alt='Divider with Lighthouse logo' />
        </div>
        <div className='mt-4 mb-6 sm:mt-6 md:mb-12 lg:mb-16'>
          <h2 className='mx-auto max-w-xl text-center text-3xl font-extrabold tracking-tight text-brandText md:text-4xl lg:text-5xl'>
            Intervene
          </h2>
        </div>
        <div
          className={'flex flex-col items-center justify-between lg:flex-row'}
        >
          <div className='mb-10 max-w-prose text-center text-lg text-brandText lg:mb-0 lg:w-1/2 lg:max-w-2xl lg:text-xl'>
            <p className={'mb-10'}>
              Polaris offers unique progress monitoring tools that adapt to your
              systems and processes. Use the data to pair students with the
              supports that are right for their circumstances.
            </p>
            <p className={''}>
              Assign students to treatments with staff caregivers that document
              their progress to be sure that nobody slips through the cracks.
            </p>
          </div>
          <div className={'max-w-xl lg:ml-10 lg:w-1/2 lg:max-w-none'}>
            <img
              src={image}
              className={'max-h-[525px] rounded-2xl shadow-xl'}
              alt={'Action details page'}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
