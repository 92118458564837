import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import HeadingWithParagraph from '../components/HeadingWithParagraph';
import PasswordResetForm from '../components/sections/PasswordReset/PasswordResetForm';

export default function PasswordReset() {
  return (
    <main className='flex h-screen flex-col justify-between'>
      <div>
        <NavBar />
        <HeadingWithParagraph
          heading={'Password Reset'}
          paragraph='Forgotten your password? Enter your email address below, and we’ll email instructions for setting a new one.'
        />
        <PasswordResetForm />
      </div>
      <Footer />
    </main>
  );
}
