export default function StateScores({
  imageOne,
  imageOneAlt,
  imageTwo,
  imageTwoAlt,
  heading,
  paragraph,
}) {
  return (
    <div className='mx-auto bg-gray-100 py-16 lg:py-32'>
      <div className='mx-auto max-w-8xl px-4 sm:px-6 lg:px-8'>
        <div className='flex flex-col items-center'>
          <h1 className='text-center text-3xl font-extrabold tracking-tight text-brandText md:text-4xl lg:text-5xl'>
            {heading}
          </h1>
          <p className='mt-3 max-w-prose text-center text-lg text-brandText lg:text-xl'>
            {paragraph}
          </p>
        </div>
        <div className='mt-10 flex flex-col items-center justify-center space-y-10 lg:flex-row lg:justify-between lg:space-y-0 lg:space-x-10'>
          <div className={`flex max-w-2xl lg:w-1/2`}>
            <img
              className='rounded-3xl shadow-xl'
              src={imageOne}
              alt={imageOneAlt}
            />
          </div>
          <div className={`flex max-w-2xl lg:w-1/2`}>
            <img
              className='rounded-3xl shadow-xl'
              src={imageTwo}
              alt={imageTwoAlt}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
