import { Link } from 'react-router-dom';
import textLogo from '../images/logos/text_logo.png';
import squareLogo from '../images/logos/square_logo.png';
import { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/solid';
import dataIcon from '../images/icons/data.svg';
import studentIcon from '../images/icons/student.svg';
import mtssIcon from '../images/icons/mtss.svg';
import adminIcon from '../images/icons/admin.svg';
import consultingIcon from '../images/icons/consulting.svg';
import customIcon from '../images/icons/custom.svg';
import Notification from './sections/Login/Notification';
import Spinner from './sections/Login/Spinner';

export default function NavBar() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const solutions = [
    {
      name: 'Integral',
      description: 'All of the critical data you need in one place.',
      href: '/integral',
      icon: dataIcon,
    },
    {
      name: 'Insight',
      description: 'Student reports that help you see the whole picture.',
      href: '/insight',
      icon: studentIcon,
    },
    {
      name: 'Lighthouse',
      description: 'The most comprehensive MTSS tool available.',
      href: '/lighthouse',
      icon: mtssIcon,
    },
    {
      name: 'Compass',
      description:
        'Analytics that engage users and promote actions to help students.',
      href: '/compass',
      icon: adminIcon,
    },
    {
      name: 'Adviser',
      description:
        'Expert guidance and consulting on MTSS, assessment, and more.',
      href: '/adviser',
      icon: consultingIcon,
    },
    {
      name: 'Adapt',
      description: 'Custom solutions to meet your unique needs.',
      href: '/adapt',
      icon: customIcon,
    },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  let distUrl = '';

  let loginUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    let response = await fetch(
      `${process.env.REACT_APP_URL}/support/district-url/?username=${e.target.username.value}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    let data = await response.json();

    if (response.status === 200) {
      distUrl =
        data.district_url +
        'login/' +
        encodeURIComponent(e.target.username.value.replace(/\./g, '%2E'));
      /* username = e.target.username.value; */
      redirectUser(e);
    } else {
      setLoading(false);
      setShow(true);
    }
  };

  const redirectUser = () => {
    window.location.href = `${distUrl}`;
  };
  return (
    <div>
      <Spinner loading={loading} />
      <Popover className='relative z-50 mx-auto max-w-8xl bg-white'>
        <div className='flex items-center justify-between px-4 py-6 sm:px-8 md:justify-start md:space-x-10'>
          <div className='flex justify-start lg:w-0 lg:flex-1'>
            <Link to='/'>
              <span className='sr-only'>Polaris Education LLC</span>
              <img className='h-10 w-auto sm:h-14' src={textLogo} alt='' />
            </Link>
          </div>
          <div className='-my-2 -mr-2 md:hidden'>
            <Popover.Button className='focus:ring-BrandPrimary inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-brandText focus:outline-none focus:ring-2 focus:ring-inset'>
              <span className='sr-only'>Open menu</span>
              <MenuIcon className='h-6 w-6' aria-hidden='true' />
            </Popover.Button>
          </div>

          <div className='hidden items-center justify-end md:flex md:flex-1 lg:w-0'>
            <form
              onSubmit={loginUser}
              className='flex flex-row duration-300 ease-in-out hover:scale-105'
            >
              <label htmlFor='email' className='sr-only'>
                username
              </label>
              <input
                type='text'
                id='username'
                name='username'
                required
                className='block w-64 rounded-l-md border-2 border-transparent bg-gray-100 py-2 text-sm font-medium text-brandText shadow-sm focus:border-none focus:bg-gray-100 focus:text-brandText focus:ring-0'
                placeholder='you@example.com'
              />

              <button
                type='submit'
                className='inline-flex items-center justify-center whitespace-nowrap rounded-r-md border-2 border-transparent bg-brandPrimary px-4 py-2 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:bg-brandPrimaryLight'
              >
                Log In
              </button>
            </form>
            <Link
              to='/contact'
              className='ml-6 inline-flex items-center justify-center whitespace-nowrap rounded-md border-2 border-transparent bg-brandPrimary px-4 py-2 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-110 hover:bg-brandPrimaryLight'
            >
              Request a Demo
            </Link>
          </div>
        </div>
        <div className='flex w-full'>
          <Popover.Group
            as='nav'
            className='ml-auto mr-10 hidden space-x-10 md:flex'
          >
            <Popover className='relative'>
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-brandText',
                      'group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2',
                    )}
                  >
                    <span className='duration-300 ease-in-out hover:scale-110'>
                      Solutions
                    </span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-brandText',
                      )}
                      aria-hidden='true'
                    />
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-200'
                    enterFrom='opacity-0 translate-y-1'
                    enterTo='opacity-100 translate-y-0'
                    leave='transition ease-in duration-150'
                    leaveFrom='opacity-100 translate-y-0'
                    leaveTo='opacity-0 translate-y-1'
                  >
                    <Popover.Panel className='absolute z-10 -ml-4 mt-3 w-screen max-w-md transform lg:left-1/2 lg:ml-0 lg:max-w-2xl lg:-translate-x-1/2'>
                      <div className='overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5'>
                        <div className='relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2'>
                          {solutions.map((solution) => (
                            <Link
                              key={solution.name}
                              to={solution.href}
                              className='-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50'
                            >
                              <div className='flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md text-brandPrimary sm:h-12 sm:w-12'>
                                <img
                                  className='h-8 w-8'
                                  src={solution.icon}
                                  alt=''
                                />
                              </div>
                              <div className='ml-4'>
                                <p className='text-base font-medium text-gray-700'>
                                  {solution.name}
                                </p>
                                <p className='mt-1 text-sm text-brandText'>
                                  {solution.description}
                                </p>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
            <Link
              to='/about'
              className='text-base font-medium text-brandText duration-300 ease-in-out hover:scale-110 hover:text-gray-700'
            >
              About
            </Link>
            <Link
              to='/resources'
              className='text-base font-medium text-brandText duration-300 ease-in-out hover:scale-110 hover:text-gray-700'
            >
              Resources
            </Link>
            <Link
              to='/blog'
              className='text-base font-medium text-brandText duration-300 ease-in-out hover:scale-110 hover:text-gray-700'
            >
              Blog
            </Link>
            <Link
              to='/funding'
              className='text-base font-medium text-brandText duration-300 ease-in-out hover:scale-110 hover:text-gray-700'
            >
              Funding
            </Link>
            <Link
              to='/contact'
              className='text-base font-medium text-brandText duration-300 ease-in-out hover:scale-110 hover:text-gray-700'
            >
              Contact
            </Link>
          </Popover.Group>
        </div>

        <Transition
          as={Fragment}
          enter='duration-200 ease-out'
          enterFrom='opacity-0 scale-95'
          enterTo='opacity-100 scale-100'
          leave='duration-100 ease-in'
          leaveFrom='opacity-100 scale-100'
          leaveTo='opacity-0 scale-95'
        >
          <Popover.Panel
            focus
            className='absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden'
          >
            <div className='divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5'>
              <div className='px-5 pt-5 pb-6'>
                <div className='flex items-center justify-between'>
                  <div>
                    <img
                      className='h-10 w-auto'
                      src={squareLogo}
                      alt='Workflow'
                    />
                  </div>
                  <div className='-mr-2'>
                    <Popover.Button className='inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-brandText focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brandPrimary'>
                      <span className='sr-only'>Close menu</span>
                      <XIcon className='h-6 w-6' aria-hidden='true' />
                    </Popover.Button>
                  </div>
                </div>
                <div className='mt-6'>
                  <nav className='grid grid-cols-1 gap-7'>
                    {solutions.map((solution) => (
                      <Link
                        key={solution.name}
                        to={solution.href}
                        className='-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50'
                      >
                        <div className='flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-white text-brandPrimary'>
                          <img className='h-6 w-6' src={solution.icon} alt='' />
                        </div>
                        <div className='ml-4 text-base font-medium text-brandText'>
                          {solution.name}
                        </div>
                      </Link>
                    ))}
                  </nav>
                </div>
              </div>
              <div className='py-6 px-5'>
                <div className='grid grid-cols-2 gap-4'>
                  <Link
                    to='/about'
                    className='text-base font-medium text-brandText hover:text-gray-700'
                  >
                    About
                  </Link>

                  <Link
                    to='/resources'
                    className='text-base font-medium text-brandText hover:text-gray-700'
                  >
                    Resources
                  </Link>

                  <Link
                    to='/blog'
                    className='text-base font-medium text-brandText hover:text-gray-700'
                  >
                    Blog
                  </Link>

                  <Link
                    to='/funding'
                    className='text-base font-medium text-brandText hover:text-gray-700'
                  >
                    Funding
                  </Link>
                  <Link
                    to='/contact'
                    className='text-base font-medium text-brandText hover:text-gray-700'
                  >
                    Contact
                  </Link>
                </div>
                <div className='mt-6'>
                  <Link
                    to='/contact'
                    className='flex w-full items-center justify-center rounded-md border border-transparent bg-brandPrimary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-brandPrimaryLight'
                  >
                    Request a Demo
                  </Link>
                  <p className='mt-6 text-center text-base font-medium text-brandText'>
                    Existing customer?{' '}
                    <Link
                      to='/login'
                      className='text-brandPrimary hover:text-brandPrimaryLight'
                    >
                      Sign in
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
        <Notification
          success={false}
          show={show}
          setShow={setShow}
          messageOne={'Failed to login!'}
          messageTwo={
            'Sorry, we were unable to log you in. Please check that your email address is correct and try again.'
          }
        />
      </Popover>
    </div>
  );
}
