import HeadingWithParagraph from '../../../HeadingWithParagraph';
import integralDividerMobile from '../../../../images/dividers/integral_divider_mobile.svg';
import integralDividerDesktop from '../../../../images/dividers/integral_divider_desktop.svg';

export default function HeadingParagraphImage({
  image,
  imageAlt,
  margins,
  heading,
  paragraph,
}) {
  return (
    <div className='relative mx-auto overflow-hidden'>
      <HeadingWithParagraph heading={heading} paragraph={paragraph} />
      <div className='mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8'>
        <div className={`mx-auto mt-12 ${margins}`}>
          <img className='mx-auto rounded-lg' src={image} alt={imageAlt} />
        </div>
      </div>
      <div className='mt-10 px-4 sm:px-6 md:hidden'>
        <img src={integralDividerMobile} alt='Divider Icon' />
      </div>
      <div className='mx-auto mt-10 hidden max-w-8xl px-6 md:block lg:px-8'>
        <img src={integralDividerDesktop} alt='Divider Icon' />
      </div>
    </div>
  );
}
