import PostAuthor from './PostAuthor';
import authorImage from '../../../images/dave.webp';

export default function PostSix() {
  return (
    <div className='relative mt-16 overflow-hidden bg-white px-2 sm:px-6 lg:mt-32 lg:px-8'>
      <div className='hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full'>
        <div
          className='relative mx-auto h-full max-w-prose text-lg'
          aria-hidden='true'
        >
          <svg
            className='absolute top-12 left-full translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='74b3fd99-0a6f-4271-bef2-e80eeafdf357'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)'
            />
          </svg>
          <svg
            className='absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='f210dbf6-a58d-4871-961e-36d5016a0f49'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#f210dbf6-a58d-4871-961e-36d5016a0f49)'
            />
          </svg>
          <svg
            className='absolute bottom-12 left-full translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='d3eb07ae-5182-43e6-857d-35c643af9034'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#d3eb07ae-5182-43e6-857d-35c643af9034)'
            />
          </svg>
        </div>
      </div>
      <div className='relative px-4 sm:px-6 lg:px-8'>
        <div className='mx-auto max-w-prose text-lg'>
          <h1 className='text-center text-3xl font-bold tracking-tight text-brandText lg:text-4xl xl:text-5xl'>
            Things Every School Leader Should Know About{' '}
            <span className='text-brandPrimary'>Leading with Data</span>
          </h1>
          <p className='mt-8 text-xl leading-8 text-brandText'>
            There are more administrators in education than ever, and most of
            them are being charged with affecting some sort of change in their
            district, typically data driven change. But if your school or
            district isn't already firing on all cylinders, there's a couple
            things you need to know as you head down the path. It's probably not
            necessary to state how difficult change can be, especially lasting
            change that starts to drive itself as opposed to a constant game of
            cat-and-mouse. The following guidelines will help you minimize the
            pain and maximize the gains from making a change.
          </p>
        </div>
        <div className='prose prose-lg prose-indigo mx-auto mt-6 text-brandText'>
          <h2 className='text-brandText'>
            1. A School/District is a Pirate Ship, not a Naval Fleet.{' '}
          </h2>
          <p>
            The success or failure you'll experience probably hinges on
            understanding this fact before anything else. While pirate captains
            are often portrayed as firmly in command of their crew, the reality
            is that no one person could manage the ship, and the captain was at
            the mercy of the men as much as the other way around. The result was
            that, as a leader, the captain had to maintain the support of the
            crew--otherwise they would mutiny.
          </p>
          <p>
            School leadership is not much different in this respect. You may
            have the title power. You may be correct. You may even be on the
            moral/ethical side, but the morality or correctness of your
            positions alone–or any of these other things–will not be enough to
            achieve a full scale organizational change. Nor can you simply
            demand the change occur. It could cause an outright mutiny among
            your staff, or worse, a silent one, where the heads nod, the forms
            are begrudgingly submitted, but you still know deep down that it's
            all a façade.You will have to build the support of your staff every
            step of the way.
          </p>
          <p>
            In our experience, the easiest way to do this is to NOT start until
            you have the critical mass. If you spend time showing people why
            change is needed, they will start to ask for it, rather than you
            having to push it. Listen to your staff. They are educated,
            professional, and have more direct recent experience than you (most
            likely). Setting all that aside because you've already figured it
            out for them is both insulting and will lead to a lower quality
            change than if you include them.
          </p>
          <h2 className='text-brandText'>2. Data/Technology Changes First</h2>
          <p>
            If we're talking about data and data entry, generally as a new tool
            comes along, people adopt it because it's useful, then eventually
            their thinking starts to change because of the new way of doing
            things. Having the tech alone will not produce change any more than
            a gym membership will improve our health if we don't go.
          </p>
          <p>
            Bearing point 1 in mind about having built a desire first, it may
            seem contradictory to suggest that we just start providing new data
            or tools. We can navigate this by realizing that change will not and
            cannot proceed in a uniform fashion. Some people no-doubt see where
            you want to go and are on board, the early adopters. Provide them
            with the support they need for growth. These early adopters play a
            key role of debugging and fixing changes via trial and error. They
            are already enthusiastic and want it to work, so they will problem
            solve.
          </p>
          <p>
            The people not on board with a change give you one shot to get it
            right--they are looking for a reason to write it off. Don't bother
            with them yet. Get your early adopters situated, supported, and
            operating smoothly. The success they experience will eventually
            start to attract others--teachers will gravitate toward anything
            they think will help kids. If you've gotten the process correct, the
            early adopters will become your first evangelists.
          </p>
          <h2 className='text-brandText'>3. Data is not a Subset of Tech</h2>
          <p>
            In fact, stop calling it data and start recognizing it for what it
            is: intelligence. Sure, a lot of technology will be used and
            deployed, and it’s sometimes the same people involved in both tech
            and intelligence work due to the overlapping skills, however it’s
            key to recognize your intelligence team as a separate entity,
            carefully managing the relationship between them and the technology.
          </p>
          <p>
            To this end, it’s also worth adding that when you are in a
            leadership position, never let anybody be in between you and the
            data team, or it’s a near guarantee the data you receive will be
            somehow distorted.
          </p>
          <h2 className='text-brandText'>
            4. It's Likely People Will Have to Change
          </h2>
          <p>
            If we already have all the analytics we need in a nice neat little
            package, great! If not, it isn’t likely we’ll get to our data
            centric goals without at least some change to the way people work.
            This could be your teachers, psychologists, social workers, etc
            having to enter data a little differently, and it could also be a
            different approach to data and accountability by the leadership
            team.
          </p>
          <p>
            If done with care, getting people to make changes to the way they
            collect and enter data need not be a huge shift to generate. First
            and foremost, it is very important to make sure that changes (that
            are just for the sake of collecting and entering data) are minimal
            and you make the process as low impact as possible for people. When
            working with clients at Polaris, we always set a goal of taking the
            same or less time/energy. Whether people have to do less, the same,
            or more, the second key step is to make sure teachers can directly
            see the benefit. In our experience, if they can see how the change
            will really help them help students, people typically will take the
            extra steps. This is why allowing more organic growth is preferred
            to top down change. Changes and adjustments occur naturally because
            educators are trying to help their students. Letting people see and
            embrace these changes at their own pace may take a little more time,
            however it’s probably preferred over a large initiative that has the
            look of a “schoolwide change” but is nothing more than inauthentic
            forms and window dressing.
          </p>
          <p>
            The implications of all 4 of these taken together are pretty
            straightforward: start by equipping your most willing with new
            tools, inquire of them what sort of data might help them that they
            don’t already have. As people make use of new tools and refine them,
            they will be quickly and more readily adopted, to the point where
            even the reluctant start to come along and give it a try. When you
            consider some of the things that tend to come along with pushing a
            top down change, it is not a big leap to suggest deep and lasting
            change can come by taking advantage of the natural process by which
            technology and change typically diffuse across groups.
          </p>
          <PostAuthor
            image={authorImage}
            name={'David Kush'}
            title={'Managing Director'}
          />
        </div>
      </div>
    </div>
  );
}
