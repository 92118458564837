export default function DividerWithHeading({ image, heading, paragraph }) {
  return (
    <div className='mx-auto'>
      <div>
        <img src={image} alt='' />
      </div>
      <div className='mt-5 flex w-full flex-col items-center'>
        <h2 className='text-center text-3xl font-extrabold tracking-tight text-brandText md:text-4xl lg:text-5xl'>
          {heading}
        </h2>
        <p className='mt-3 max-w-prose text-center text-lg text-brandText lg:text-xl'>
          {paragraph}
        </p>
      </div>
    </div>
  );
}
