import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import MyBook from '../components/MyBook/MyBook';
import { Link } from 'react-router-dom';

export default function Book() {
  return (
    <main>
      <NavBar />
      <div className='mx-auto mt-16 max-w-8xl px-0 sm:px-6 lg:mt-32 lg:px-8'>
        <div className='mx-auto'>
          <div className='flex flex-col items-center px-4 sm:px-6 lg:px-8'>
            <h1 className='text-center text-3xl font-extrabold tracking-tight text-brandText md:text-4xl lg:text-5xl'>
              Turn the Page to Get started
            </h1>
            <p className='mt-3 max-w-[68ch] text-center text-lg text-brandText lg:text-xl'>
              Enjoy our lighthearted tale of data gone wrong. Our goal is help
              schools use their data in a unified, harmonious way to increase
              student well being.{' '}
              <Link className='text-brandPrimary' to={'/contact'}>
                Schedule a demo
              </Link>{' '}
              to learn more!
            </p>
          </div>
        </div>
      </div>
      <MyBook />
      <Footer />
    </main>
  );
}
