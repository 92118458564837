import Footer from '../../components/Footer';
import GrayCTA from '../../components/GrayCTA';
import NavBar from '../../components/NavBar';
import PostFive from '../../components/sections/BlogPost/PostFive';

export default function BlogPostOne() {
  return (
    <main>
      <NavBar />
      <PostFive />
      <GrayCTA margins={'mt-16 lg:mt-32'} />
      <Footer />
    </main>
  );
}
