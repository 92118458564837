import { Link } from 'react-router-dom';

export default function ThankYou() {
  return (
    <div className='mx-auto h-screen w-screen'>
      <main className='grid min-h-full place-items-center bg-white py-24 px-6 sm:py-32 lg:px-8'>
        <div className='text-center'>
          <h1 className='text-4xl font-bold tracking-tight text-brandText sm:text-5xl'>
            Request Received
          </h1>
          <p className='mx-auto mt-6 max-w-xl text-base leading-7 text-brandText'>
            Thank you for reaching out to us. We have received your request and
            will be in touch with the information you have requested shortly. If
            you have any further questions or concerns, please feel free to
            reach out to us again.
          </p>
          <div className='mt-10 sm:flex sm:justify-center'>
            <div className='rounded-md shadow'>
              <Link
                to='/contact'
                className='md:px-18 flex w-full items-center justify-center rounded-md border border-transparent bg-brandPrimary px-6 py-2 text-base font-medium text-white duration-300 ease-in-out hover:bg-brandPrimaryLight md:py-3 md:text-lg md:hover:scale-110'
              >
                Go back
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
