import heroCutout from '../../../images/hero_cutout.webp';
import { Link } from 'react-router-dom';

export default function Hero() {
  return (
    <section className='hero-container mx-auto max-w-8xl px-4 sm:px-6 lg:h-[800px] lg:px-8'>
      {/* Tablet and desktop Hero */}
      <div className="mx-auto hidden w-full bg-[url('images/hero.webp')] bg-cover bg-center lg:flex">
        <div className='hero-gradient-container items-left flex h-[800px] w-[550px] flex-col justify-center px-8 text-center xl:w-[650px]'>
          <div className='items-left flex h-[800px] w-[90%] flex-col justify-center text-left'>
            <h1 className='mt-10 text-4xl font-extrabold tracking-tight text-brandText sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl'>
              <span className='block lg:leading-[55px] xl:inline xl:leading-[70px]'>
                Does your school have struggling
              </span>{' '}
              <span className='block text-brandPrimary lg:leading-[55px] xl:inline xl:leading-[70px]'>
                students that have yet to be identified?
              </span>
            </h1>
            <p className='mx-auto mt-3 max-w-md text-lg text-brandText sm:text-xl md:mt-5 md:max-w-3xl'>
              We get it! Polaris is committed to helping students and adults by
              equipping your Student Service Team with the most sophisticated,
              yet intuitive, MTSS management tools available.
            </p>
            <div className='mt-10 sm:flex sm:justify-center lg:justify-start'>
              <div className='rounded-md shadow'>
                <Link
                  to='/about'
                  className='flex w-full items-center justify-center rounded-md border border-transparent bg-brandPrimary px-8 py-3 text-base font-medium text-white duration-300 ease-in-out hover:scale-110 hover:bg-brandPrimaryLight md:py-4 md:px-10 md:text-lg'
                >
                  Learn more
                </Link>
              </div>
              <div className='mt-3 rounded-md shadow sm:mt-0 sm:ml-3'>
                <Link
                  to='/contact'
                  className='flex w-full items-center justify-center rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-brandPrimary duration-300 ease-in-out hover:scale-110 hover:bg-gray-50 md:py-4 md:px-10 md:text-lg'
                >
                  Request a Demo
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile Hero */}
      <div className='mt-10 flex flex-col items-center md:mt-20 lg:hidden'>
        <div className='flex w-full flex-col items-center text-center'>
          <h1 className='text-4xl font-extrabold tracking-tight text-brandText sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl'>
            <span className='block leading-[45px] sm:leading-[55px] md:leading-[65px] xl:inline'>
              Does your school have struggling
            </span>{' '}
            <span className='block leading-[45px] text-brandPrimary sm:leading-[55px] md:leading-[65px] xl:inline'>
              students that have yet to be identified?
            </span>
          </h1>
          <div className='mt-5'>
            <img className='' src={heroCutout} alt='' />
          </div>
          <p className='mx-auto mt-8 max-w-md text-lg text-gray-500 sm:text-xl md:mt-10 md:max-w-3xl'>
            We get it! Polaris is committed to helping students and adults by
            equipping your Student Service Team with the most sophisticated, yet
            intuitive MTSS management tools available.
          </p>
          <div className='mt-8 w-full sm:flex sm:justify-center md:mt-10 lg:justify-start'>
            <div className='rounded-md shadow'>
              <Link
                to='/about'
                className='flex w-full items-center justify-center rounded-md border border-transparent bg-brandPrimary px-8 py-3 text-base font-medium text-white hover:bg-brandPrimaryLight md:py-4 md:px-10 md:text-lg'
              >
                Learn more
              </Link>
            </div>
            <div className='mt-3 rounded-md shadow sm:mt-0 sm:ml-3'>
              <Link
                to='contact'
                className='flex w-full items-center justify-center rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-brandPrimary hover:bg-gray-50 md:py-4 md:px-10 md:text-lg'
              >
                Request a Demo
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
