import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import HeadingWithParagraph from '../components/HeadingWithParagraph';
import GrayCTA from '../components/GrayCTA';
import ResourceList from '../components/sections/ResourcesHome/ResourceList';

export default function ResourcesHome() {
  return (
    <main>
      <NavBar />
      <HeadingWithParagraph
        heading={'Resource Center'}
        paragraph='Select a category to view a curated list of valuable resources from across the web.'
      />
      <ResourceList />
      <GrayCTA margins={''} />
      <Footer />
    </main>
  );
}
