import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import PageTopper from '../../components/sections/Services/PageTopper';
import image from '../../images/service_cards/adapt_card.png';
import manAndWomanImage from '../../images/service_pages/adviser/man_and_woman.webp';
import ImageLeftTextRight from '../../components/sections/Services/ImageLeftTextRight';
import puzzleImage from '../../images/service_pages/adapt/puzzle.webp';
import ImageRightTextLeft from '../../components/sections/Services/ImageRightTextLeft';
import WideImageWithText from '../../components/sections/Services/Adapt/WideImageWithText';

export default function Lighthouse() {
  return (
    <main>
      <NavBar />
      <PageTopper
        heading={
          'Terminally Unique? Have\u00A0a\u00A0Particular Problem or Case?'
        }
        headingSpan={'We Can Help!'}
        paragraph={
          'Adapt, our custom solutions group, can design a solution that fits your school’s needs. This can be done as an add-on to the Polaris suite or as a stand-alone project. '
        }
        image={image}
        imageAlt={'Adapt Logo'}
      />
      <ImageLeftTextRight
        image={manAndWomanImage}
        imageAlt={'A man and woman high-fiving'}
        heading={'Extend the Power and Reach of Your New Data System'}
        paragraphOne={`Whether it’s a few forms or pages that allow your school to automate a tedious task or a major application build, the Adapt team can help you multiply the power of the data and tools available on Polaris-edu.com. Since every client gets their own version of the app, the possibilities are endless.`}
        paragraphTwo={``}
        paragraphOneClasses={''}
        paragraphTwoClasses={'hidden'}
        containerClasses={'mx-auto py-20 lg:py-32 bg-white'}
        imageContainerClasses={'max-w-xl lg:max-w-none'}
        imageClasses={'rounded-3xl shadow-xl max-h-[475px] lg:min-w-[450px]'}
        flexContainerClasses={
          'flex flex-col items-center lg:space-x-10 justify-evenly lg:flex-row'
        }
      />
      <ImageRightTextLeft
        image={puzzleImage}
        imageAlt={'Missing puzzle piece'}
        heading={'Not Sure What You Need?'}
        paragraphOne={`No Problem! We are here to find that perfect customized solution for your data and technology issues.`}
        paragraphTwo={``}
        paragraphOneClasses={''}
        paragraphTwoClasses={''}
        containerClasses={'mx-auto mt-0 bg-gray-100 py-20 lg:py-32'}
        imageContainerClasses={'max-w-2xl lg:max-w-none'}
        imageClasses={'rounded-3xl lg:min-w-[450px] shadow-xl max-h-[450px]'}
        flexContainerClasses={
          'flex flex-col items-center lg:space-x-10 justify-evenly lg:flex-row'
        }
      />
      <WideImageWithText />
      <Footer />
    </main>
  );
}
