import handsImage from '../../../../images/service_pages/adapt/hands.webp';
import mobileDivider from '../../../../images/dividers/adapt_divider_mobile.png';
import desktopDivider from '../../../../images/dividers/adapt_divider_desktop.png';

export default function WideImageWithText() {
  return (
    <section className='mx-auto max-w-8xl py-20 px-4 sm:px-6 lg:py-32 lg:px-8'>
      <div className='mx-auto'>
        <div className='flex flex-col items-center'>
          <div>
            <div className='mx-auto max-w-8xl px-4 sm:px-6 md:hidden lg:px-8'>
              <img src={mobileDivider} alt='Divider with compass logo' />
            </div>
            <div className='mx-auto hidden max-w-8xl px-4 sm:px-6 md:block lg:px-8'>
              <img src={desktopDivider} alt='Divider with compass logo' />
            </div>
          </div>
          <h2 className='mt-6 text-center text-3xl font-extrabold tracking-tight text-brandText md:mt-10 md:text-4xl lg:text-5xl'>
            Get the Conversation Started
          </h2>
          <p className='mt-3 max-w-prose text-center text-lg text-brandText lg:text-xl'>
            Whether you already know exactly what you want or just have a
            problem to solve, we are here to help. Reach out to our expert staff
            today for a consultation and take a step in the right direction for
            solutions that fit your school’s needs!
          </p>
        </div>
        <div className='mx-auto mt-10 max-w-6xl md:px-5 xl:px-0'>
          <img
            className='mx-auto rounded-2xl shadow-xl'
            src={handsImage}
            alt='Female teacher high-fiving a student.'
          />
        </div>
      </div>
    </section>
  );
}
