import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import Resources from '../../components/sections/ResourcesList/Resources';
import Heading from '../../components/Heading';
import GrayCTA from '../../components/GrayCTA';

export default function ToolkitResources() {
  const resourceList = [
    {
      title: 'Washington School District Data Team Toolkit',
      source: '- Very Comprehensive- k12 WA',
      summary:
        'A 320 page toolkit that will help any school/district learn to work to create, select, and analyze the right data for improvement.',
      href: 'https://www.k12.wa.us/sites/default/files/public/ossi/schoolimprovement/pubdocs/datateamtoolkit.pdf',
      imageUrl:
        'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    },
    {
      title: 'District Data Team Toolkit',
      source: '- Department of Education, Massachusetts',
      summary:
        'A 313 page toolkit for creating an effective and efficient data team. This will help any data team.',
      href: 'https://www.doe.mass.edu/accountability/toolkit/district-data-toolkit.pdf',
      imageUrl:
        'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    },
  ];
  return (
    <main>
      <NavBar />
      <Heading heading={'Toolkits'} />
      <Resources resourceList={resourceList} />
      <GrayCTA />
      <Footer />
    </main>
  );
}
