import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import BlogPosts from '../components/sections/BlogHome/BlogPosts';
import HeadingWithParagraph from '../components/HeadingWithParagraph';
import GrayCTA from '../components/GrayCTA';

export default function BlogHome() {
  return (
    <main>
      <NavBar />
      <HeadingWithParagraph
        heading={'From The Blog'}
        paragraph='Check out our latest blog posts to stay informed and learn whats going on with the company and in the industry.'
      />
      <BlogPosts />
      <GrayCTA margins={'py-10'} />
      <Footer />
    </main>
  );
}
