import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import PageTopper from '../../components/sections/Services/PageTopper';
import image from '../../images/service_cards/adviser_card.png';
import manAndWomanImage from '../../images/service_pages/adviser/man_and_woman.webp';
import ImageRightTextLeft from '../../components/sections/Services/ImageRightTextLeft';
import WideImageWithText from '../../components/sections/Services/Adviser/WideImageWithText';
import mobileDivider from '../../images/dividers/adviser_divider_mobile.svg';
import desktopDivider from '../../images/dividers/adviser_divider_desktop.svg';
import studentsImage from '../../images/service_pages/adviser/students.webp';

export default function Lighthouse() {
  return (
    <main>
      <NavBar />
      <PageTopper
        heading={'Expert Guidance,'}
        headingSpan={'Maximum Impact'}
        paragraph={`Whether it’s the development of KPIs or monitoring school progress all the way down through the nitty gritty details of MTSS programs and Analytics, our team is ready to help.`}
        image={image}
        imageAlt={'Adviser Logo'}
      />
      <ImageRightTextLeft
        image={manAndWomanImage}
        imageAlt={'Man and woman high-fiving'}
        heading={'We Empower Your Staff'}
        paragraphOne={`It's always helpful to have an experienced guide with you in your journey to school change. No matter the data needs, our Advisor team is with you every step to ensure you are meeting your school's growth and improvement needs.`}
        paragraphTwo={``}
        paragraphOneClasses={''}
        paragraphTwoClasses={'hidden'}
        containerClasses={'mx-auto mt-20 lg:mt-24 bg-gray-100 py-20 lg:py-32'}
        imageContainerClasses={'max-w-2xl lg:max-w-none'}
        imageClasses={'rounded-3xl lg:min-w-[500px] shadow-xl max-h-[500px]'}
        flexContainerClasses={
          'flex flex-col items-center lg:space-x-10 justify-between lg:flex-row'
        }
      />
      <WideImageWithText />
      <div>
        <div className='mx-auto max-w-8xl px-4 sm:px-6 md:hidden lg:px-8'>
          <img src={mobileDivider} alt='Divider with compass logo' />
        </div>
        <div className='mx-auto hidden max-w-8xl px-4 sm:px-6 md:block lg:px-8'>
          <img src={desktopDivider} alt='Divider with compass logo' />
        </div>
      </div>
      <ImageRightTextLeft
        image={studentsImage}
        imageAlt={'Two young students, one of which has his hand up.'}
        heading={'Let Us Help You Make The Difference'}
        paragraphOne={`Our team is ready to get you started today. Let us show you the difference Advisor can make 
        for your school’s growth and improvement.`}
        paragraphTwo={``}
        paragraphOneClasses={''}
        paragraphTwoClasses={'hidden'}
        containerClasses={'mx-auto mt-6 md:mt-10 bg-gray-100 py-20 lg:py-32'}
        imageContainerClasses={'max-w-2xl lg:min-w-[350px] lg:max-w-none'}
        imageClasses={'rounded-3xl shadow-xl max-h-[600px]'}
        flexContainerClasses={
          'flex flex-col items-center lg:space-x-10 justify-evenly lg:flex-row'
        }
      />
      <Footer />
    </main>
  );
}
