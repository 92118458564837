import postImage from '../../../images/blog/post_four_image.webp';
import PostAuthor from './PostAuthor';
import authorImage from '../../../images/dave.webp';

export default function PostFour() {
  return (
    <div className='relative mt-16 overflow-hidden bg-white px-2 sm:px-6 lg:mt-32 lg:px-8'>
      <div className='hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full'>
        <div
          className='relative mx-auto h-full max-w-prose text-lg'
          aria-hidden='true'
        >
          <svg
            className='absolute top-12 left-full translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='74b3fd99-0a6f-4271-bef2-e80eeafdf357'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)'
            />
          </svg>
          <svg
            className='absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='f210dbf6-a58d-4871-961e-36d5016a0f49'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#f210dbf6-a58d-4871-961e-36d5016a0f49)'
            />
          </svg>
          <svg
            className='absolute bottom-12 left-full translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='d3eb07ae-5182-43e6-857d-35c643af9034'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#d3eb07ae-5182-43e6-857d-35c643af9034)'
            />
          </svg>
        </div>
      </div>
      <div className='relative px-4 sm:px-6 lg:px-8'>
        <div className='mx-auto max-w-prose text-lg'>
          <h1 className='text-center text-3xl font-bold tracking-tight text-brandText lg:text-4xl xl:text-5xl'>
            Data <span className='text-brandPrimary'>DRIVEN</span> Schools Part
            3
          </h1>
          <p className='mt-8 text-xl leading-8 text-brandText'>
            Part I of this series looked at how to set up your data
            infrastructure for success. In Part II we examined some ways to
            better equip the staff to utilize data. In this final part on being
            Data Driven, we will look at a few strategies that you can employ to
            build a strong culture of analytics.
          </p>
        </div>
        <div className='prose prose-lg prose-indigo mx-auto mt-6 text-brandText'>
          <h2 className='text-brandText'>
            Build Critical Mass, or the Naysayers will Become the Critical Mass
          </h2>
          <p>
            Most people reading this have seen those signs on the side of the
            road that say, “I BUY HOUSES” with a phone number. The real estate
            investor that placed the sign knows that she could spend her time
            hunting for diamonds in the rough, OR she can put up signs and get
            the diamonds coming to her. Buy-in works much the same way. If you
            don’t have buy in, your work will be laborious, unenjoyable, and
            probably unsuccessful. It’s also likely to ruin your personal and
            professional reputation. On the other hand, if the staff truly
            believe in what you’re doing, they will work much more readily
            toward the goal. If it’s really firing on all cylinders they will
            start to hold each other accountable, which takes even more of the
            pressure off of administrators. Take your time, do your research,
            and be willing to spend time sharing and discussing with teachers
            for a while. It needs to be a movement, not an initiative.
          </p>
          <h2 className='text-brandText'>
            Develop parameters around how data will be used and won’t be used
          </h2>
          <p>
            There is likely to be fear, suspicion, and or reservation about
            using data, depending on the current data culture. By being very
            specific about how data is being used and not being used, we can
            calm the staff. One of the most important things to address, if not
            already part of your collective bargaining agreement, in what way
            data relates, if at all, to evaluations. This is influenced heavily
            by local policies and practices.
          </p>
          <p>
            It’s also important to assure staff that, within limits, context
            does matter. A frustrated teacher once articulated that she feared a
            scenario where “Suppose the students of my colleague average 87%
            while mine average 83%. So now I’m told to pitch everything I do
            that’s different?” While the likelihood of that coming to pass is
            low, it reveals an important issue. If the numbers were 87% and 23%
            it’s likely there would be some sort of intervention. Establishing
            some guidelines and starting practices is helpful, as well being
            clear with yourself and the staff that are guidelines, not rules,
            and they will continue to evolve transparently.
          </p>
          <p>
            Remember: Numbers only aid decisions. It’s people that must make
            decisions.
          </p>
          <h2 className='text-brandText'>
            Set a tone that shows that the plans are more than words
          </h2>
          <p>
            How quickly you can proceed in this area depends on a variety of
            local conditions. In general though, if this is very new to the
            district/school, start slow. Have teachers share their own data with
            teachers of similar content or students (ie: people that can
            understand the context). In time you can build toward automatic
            sharing, such as dashboards. With each evolution, the first couple
            times through the process may be awkward, but in time people will
            see that the process is beneficial and supportive, not punitive, and
            continue to open up more.
          </p>
          <figure>
            <img
              className='w-full rounded-lg'
              src={postImage}
              alt=''
              width={1310}
              height={873}
            />
            <figcaption>
              Taking time to build thoughtful data practices will have a big
              long-term impact.
            </figcaption>
          </figure>
          <h2 className='text-brandText'>Proceed toward data openness</h2>
          <p>
            It’s advisable to share and be open with data as much as possible.
            In a perfect world teachers would be able to look at each other’s
            students/data, evaluate it accurately, and collaborate to share
            knowledge and improve student outcomes where possible.
            Realistically, most schools are very far from that version of
            perfect. Teaching is strange in the sense that it’s an art and a
            science at the same time, teacher often create their lessons and
            materials, and like artisans, often take great pride in their work.
            What I’ve found is not that most teachers fear being judged by their
            data, they fear being misjudged by the data. This fear is not
            surprising given the vast amount of context often involved in
            student achievement as well as the “distance” between the central
            administrators and the students.
          </p>
          <h2 className='text-brandText'>
            Don’t expect them to be data driven unless you are
          </h2>
          <p>
            Data driven means commitment to the continuous improvement of
            carefully selected KPIs in reasonable increments. People generally
            will not work toward goals they don’t think they can reach. If you
            set a goal that no student would ever miss a homework assignment
            ever, you’d lose credibility. Our goals should require us to stretch
            and grow, but they must be attainable. It’s essential to truly hear
            your staff during this process. They have the most comprehensive and
            direct information about the student and learning conditions. They
            may need to be nudged at times as well, it’s a two way street, just
            make sure you’re not brushing away staff concerns or major obstacles
            to learning with platitudes like, “just differentiate.” If we don’t
            truly acknowledge what we are experiencing, we can’t really address
            the issues, leading to stagnant results.
          </p>
          <p>
            If the data points are vertically articulated everybody should be
            pulling on the same rope, even if they are on different levels in
            the district. This creates a predictable, safe environment for using
            data to help students. With data points based on values important to
            your stakeholders, their increased motivation to work toward
            improvement, less pressure to respond to momentary issues.
          </p>
          <PostAuthor
            image={authorImage}
            name={'David Kush'}
            title={'Managing Director'}
          />
        </div>
      </div>
    </div>
  );
}
