import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import divider from '../../../images/solution_divider.svg';
import DividerWithHeading from '../../DividerWithHeading';

const faqs = [
  {
    question: 'What is Polaris?',
    answer:
      'Polaris is a complete system for maximizing student success.  We integrate data from your key sources, and then provide first class analytics and tools to allow you to locate and assist struggling students. Polaris adapts to your needs, and manages all the data and details so spend less time on spreadsheets and reports, more time on what matters most.',
  },
  {
    question: 'Who should use Polaris?',
    answer:
      'Our products are designed for any district or school that wants to better use data and communicate more effectively in order to help students.  Whether you have a functioning MTSS program or are trying to build one, we can help equip you with tools to make your program more effective, save time, and reach more students.',
  },
  {
    question:
      'What are some of the benefits schools using Polaris report seeing?',
    answer:
      'Schools using Polaris are able to reach more students, intervene earlier, and manage those interventions more effectively.  This leads to improved outcomes for students, whether these are test scores, grades, attendance, or behavior.',
  },
  {
    question: 'How often is data updated?',
    answer:
      'We typically sync with your student information system and other data sources on a nightly basis, although other frequencies are available upon request.  Any data entered into Polaris by your staff, like Notes or Actions, is instantly visible to other users.',
  },
  {
    question:
      'How long does it take to start seeing the benefits of using Polaris?',
    answer:
      'Your staff will see the benefits immediately, as it becomes much easier to organize, prioritize, and communicate with other staff about students that are struggling.  As soon as staff members jump in and begin utilizing tools like the MTSS Locator or the Student Report, they will start uncovering more students to help.  Measurable results should be visible within a few weeks.',
  },
  {
    question: 'What is the licensing model?',
    answer:
      'We keep it simple to give you maximum access and leverage.  Our base price includes access to all products for all staff.  Licenses renew annually.',
  },
  {
    question: 'What does the setup and onboarding process look like?',
    answer:
      'Our team works with your staff to get things up and running quickly. We will ask for a technical point of contact for data integration and account setup.  Meanwhile, your account manager will work with you to help get your staff onboarded familiarized with how to use Polaris within your school.',
  },
  // More questions...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function FAQ() {
  return (
    <div className=''>
      <div className='mx-auto max-w-7xl px-4 pt-16 sm:px-6 lg:px-8'>
        <DividerWithHeading
          image={divider}
          heading={'Frequently Asked Questions'}
          paragraph={''}
        />
        <div className='mx-auto max-w-3xl divide-y-2 divide-gray-200'>
          <dl className='mt-6 space-y-6 divide-y divide-gray-200'>
            {faqs.map((faq) => (
              <Disclosure as='div' key={faq.question} className='pt-6'>
                {({ open }) => (
                  <>
                    <dt className='text-lg'>
                      <Disclosure.Button className='flex w-full items-start justify-between text-left text-brandText'>
                        <span className='font-medium text-brandText'>
                          {faq.question}
                        </span>
                        <span className='ml-6 flex h-7 items-center'>
                          <ChevronDownIcon
                            className={classNames(
                              open ? '-rotate-180' : 'rotate-0',
                              'h-6 w-6 transform',
                            )}
                            aria-hidden='true'
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as='dd' className='mt-2 pr-12'>
                      <p className='text-base text-brandText'>{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
