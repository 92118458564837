import image from '../../../../images/service_pages/adviser/educators.webp';

export default function WideImageWithText() {
  return (
    <section className='mx-auto max-w-8xl py-16 px-4 sm:px-6 lg:py-32 lg:px-8'>
      <div className='mx-auto'>
        <div className='flex flex-col items-center'>
          <h2 className='text-center text-3xl font-extrabold tracking-tight text-brandText md:text-4xl lg:text-5xl'>
            Consulting That Will Lead to Change
          </h2>
          <p className='mt-3 max-w-prose text-center text-lg text-brandText lg:text-xl'>
            Our consultants find ways to leverage your existing resources to get
            the largest benefit for students. Solutions are designed that fit
            within your unique circumstances, keep your staff in the driver's
            seat, and empower them to lead successful change.{' '}
          </p>
        </div>
        <div className='mx-auto mt-10 max-w-3xl md:px-5 xl:px-0'>
          <img
            className='mx-auto rounded-2xl shadow-xl'
            src={image}
            alt='Advisor lecturing a small group of educators.'
          />
        </div>
      </div>
    </section>
  );
}
