import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import Resources from '../../components/sections/ResourcesList/Resources';
import Heading from '../../components/Heading';
import GrayCTA from '../../components/GrayCTA';

export default function UseOfDataResources() {
  const resourceList = [
    {
      title: 'Using Data to Improve Schools',
      source: '- AASA- Superintendents Organization',
      summary:
        'Entitled, “Using Data to Improve Schools: What’s Working.” This will help data teams to develop four key elements of any good team: Learning about data selection, using data in decision making, communication and public engagement, and strategies for success.',
      href: 'https://aasa.org/uploadedFiles/Policy_and_Advocacy/files/UsingDataToImproveSchools.pdf',
      imageUrl:
        'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    },
    {
      title: '3 Tips to Use Data Effectively',
      source: '- Edutopia',
      summary:
        'Discusses the impactful use of data to maximize student benefits.',
      href: 'https://www.edutopia.org/article/3-tips-use-data-effectively',
      imageUrl:
        'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    },
    {
      title: 'Data in Education',
      source: '- Learning A-Z',
      summary:
        'Part one of a two-part series. Goes over the basics of data use in education for those team members who need more confidence in speaking the lingo of data.',
      href: 'https://www.learninga-z.com/site/breakroom/data-in-education',
      imageUrl:
        'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    },
    {
      title: 'Building a DataRich Culture',
      source: '- Learning A-Z',
      summary:
        'The second part in the data series from Iris Garner. The article discusses the challenges to and ways to create a data rich culture in a school system.',
      href: 'https://www.learninga-z.com/site/breakroom/building-data-rich-culture',
      imageUrl:
        'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    },
    {
      title:
        'Data Driven Decision Making in Education: 11 Tips for Teachers & Administrators',
      source: '- American University School of Education',
      summary:
        'Tips for educators from the American School of Education for getting started with data use in a school system.',
      href: 'https://soeonline.american.edu/blog/data-driven-decision-making-in-education',
      imageUrl:
        'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    },
  ];
  return (
    <main>
      <NavBar />
      <Heading heading={'Use of Data'} />
      <Resources resourceList={resourceList} />
      <GrayCTA />
      <Footer />
    </main>
  );
}
