import squareLogo from '../../../images/logos/square_logo.png';
import { useState } from 'react';
import Notification from './Notification';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';

export default function Login() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  let distUrl = '';

  let loginUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    let response = await fetch(
      `${process.env.REACT_APP_URL}/support/district-url/?username=${e.target.username.value}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    let data = await response.json();

    if (response.status === 200) {
      distUrl =
        data.district_url +
        'login/' +
        encodeURIComponent(e.target.username.value.replace(/\./g, '%2E'));
      /* username = e.target.username.value; */
      redirectUser(e);
    } else {
      setLoading(false);
      setShow(true);
    }
  };

  const redirectUser = () => {
    window.location.href = `${distUrl}`;
  };

  return (
    <div>
      <Spinner loading={loading} />
      <div className='flex min-h-screen flex-col justify-center bg-gray-50 py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <img
            className='mx-auto h-20 w-auto'
            src={squareLogo}
            alt='Polaris Education Logo'
          />
          <h2 className='mt-6 text-center text-3xl font-extrabold tracking-tight text-brandText'>
            Sign in to your account
          </h2>
          <p className='mt-2 text-center text-sm text-gray-600'>
            Or{' '}
            <Link
              to={'/contact'}
              className='font-medium text-brandPrimary hover:text-brandPrimaryLight'
            >
              request a free demo
            </Link>
          </p>
        </div>

        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
          <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
            <form className='space-y-6' onSubmit={loginUser}>
              <div>
                <label
                  htmlFor='username'
                  className='block text-sm font-medium text-brandText'
                >
                  Username
                </label>
                <div className='mt-1'>
                  <input
                    type='text'
                    id='username'
                    name='username'
                    placeholder='Username'
                    required
                    className='block w-full appearance-none rounded-md border border-gray-300 bg-white px-3 py-2 text-brandText placeholder-gray-400 shadow-sm focus:border-brandPrimary focus:bg-white focus:text-brandText focus:outline-none focus:ring-brandPrimary sm:text-sm'
                  />
                </div>
              </div>

              <div className=''>
                <button
                  type='submit'
                  className='flex w-full items-center justify-center rounded-md border border-transparent bg-brandPrimary py-2 px-4 text-base font-medium text-white duration-300 ease-in-out hover:bg-brandPrimaryLight focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2 sm:hover:scale-110'
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <Notification
          success={false}
          show={show}
          setShow={setShow}
          messageOne={'Failed to login!'}
          messageTwo={
            'Sorry, we were unable to log you in. Please check that your email address is correct and try again.'
          }
        />
      </div>
    </div>
  );
}
