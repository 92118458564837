import image from '../../../../images/service_pages/lighthouse/monitor_progress.png';

export default function ImageLeft() {
  return (
    <section className={'mx-auto mt-20 bg-gray-100 py-20 lg:mt-24 lg:py-32'}>
      <div className='mx-auto px-4 sm:px-6 lg:max-w-8xl lg:px-8'>
        <div className='mb-6 md:mb-12 lg:mb-16'>
          <h2 className='mx-auto max-w-xl text-center text-3xl font-extrabold tracking-tight text-brandText md:text-4xl lg:text-5xl'>
            Monitor Progress
          </h2>
        </div>
        <div
          className={
            'flex flex-col items-center justify-evenly lg:flex-row lg:space-x-10'
          }
        >
          <div className={'max-w-xl lg:w-1/2 lg:max-w-none'}>
            <img
              src={image}
              className={
                'max-h-[500px] min-w-[400px] rounded-lg shadow-xl ring-2 ring-white'
              }
              alt='current actions page'
            />
          </div>
          <div className='mt-10 max-w-prose text-center text-lg text-brandText lg:mt-0 lg:w-1/2 lg:max-w-2xl lg:text-xl'>
            <p className={'mb-10'}>
              Select the tools and assessments that work best for your students
              and interventions.  Similar to Polaris’s multi-tiered approach to
              identifying students, both qualitative and quantitative progress
              monitoring is supported.  Caregivers can add notes and comments to
              in-progress interventions. 
            </p>
            <p className={''}>
              You can also add assessment scores from any assessment you use to
              track improvement.  These are accompanied by first-rate analytics
              that make it easy to see what’s going on.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
