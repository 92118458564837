import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import Resources from '../../components/sections/ResourcesList/Resources';
import Heading from '../../components/Heading';
import GrayCTA from '../../components/GrayCTA';

export default function StandardsBased() {
  const resourceList = [
    {
      title: 'Four Strategies for Implementing Standards-Based Learning',
      source: '- Edutopia',
      summary:
        'A stepped approach to a realistic shift to Standards-Based Learning for any school district/school.',
      href: 'https://www.edutopia.org/article/4-strategies-implementing-standards-based-learning',
      imageUrl:
        'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    },
    {
      title: 'Standards-Based',
      source: '- Glossary of Education Reform',
      summary:
        'A glossary of terms relating to standards-based grading, learning, standards-referenced, curriculum, and assessments. Also includes rubrics and examples.',
      href: 'https://www.edglossary.org/standards-based/',
      imageUrl:
        'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    },
    {
      title: 'The Mismeasure of Learning',
      source: '- Education Next',
      summary:
        'A look into the selecting and using the correct standards to improve the teaching, learning, and grading that occurs in schools. ',
      href: 'https://www.educationnext.org/the-mismeasure-of-learning/',
      imageUrl:
        'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    },
    {
      title: 'How to Grade for Learning',
      source: "- Kevin O'Connor - Video (~60 minutes)",
      summary:
        'Eight guidelines for standards-based grading including purpose, content standards, performance standards, learning skills/work habits, late work, and academic dishonesty.',
      href: 'https://www.youtube.com/watch?v=BL7bRbGGlOQ&ab_channel=Corwin',
      imageUrl:
        'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    },
  ];
  return (
    <main>
      <NavBar />
      <Heading heading={'Standards-Based Instruction/Teaching'} />
      <Resources resourceList={resourceList} />
      <GrayCTA />
      <Footer />
    </main>
  );
}
