import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import Resources from '../../components/sections/ResourcesList/Resources';
import Heading from '../../components/Heading';
import GrayCTA from '../../components/GrayCTA';

export default function EquityDataResources() {
  const resourceList = [
    {
      title: 'Using Data to Advance Racial Equity ',
      source: '– Edutopia',
      summary:
        'This article introduces the reader to the use of data in your equity conversations.',
      href: 'https://www.edutopia.org/article/using-data-advance-racial-equity',
      imageUrl:
        'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    },
    {
      title: 'Looking at Data Through an Equity Lens',
      source: '- ASCD',
      summary:
        'This article discusses tackling issues in equity from an inquiry approach.',
      href: 'https://www.ascd.org/el/articles/looking-at-data-through-an-equity-lens',
      imageUrl:
        'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    },
    {
      title: 'Does De-tracking Promote Educational Equity?',
      source: '– Brookings Institute ',
      summary:
        'De-tracking has gained new life in education in recent years. This article looks at the studies in de-tracking and the success measures that were used in the more successful schools.',
      href: 'https://www.brookings.edu/blog/brown-center-chalkboard/2021/10/04/does-detracking-promote-educational-equity/',
      imageUrl:
        'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    },
  ];
  return (
    <main>
      <NavBar />
      <Heading heading={'Equity and Data'} />
      <Resources resourceList={resourceList} />
      <GrayCTA />
      <Footer />
    </main>
  );
}
