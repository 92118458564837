export default function Newsletter() {
  return (
    <div className='relative mx-auto mt-16 lg:mt-32'>
      <div
        className='bg-warm-gray-50 absolute left-0 right-0 h-1/2'
        aria-hidden='true'
      />
      <div className='relative mx-auto '>
        <div className='mx-auto bg-gradient-to-r from-brandPrimary to-brandPrimaryDark'>
          <div className='mx-auto max-w-8xl px-4 py-10 sm:px-6 sm:py-16 lg:flex lg:items-center lg:px-8 lg:py-20'>
            <div className=' lg:w-0 lg:flex-1'>
              <h2 className='text-3xl font-extrabold tracking-tight text-white'>
                Sign up for our newsletter
              </h2>
              <p className='mt-4 max-w-xl text-lg font-medium text-white'>
                Stay updated with Polaris Education! Subscribe to our newsletter
                for exclusive insights, offers, and the latest news. Be a part
                of our community today!
              </p>
            </div>
            <div className='mt-12 sm:w-full sm:max-w-md lg:mt-0 lg:ml-8 lg:flex-1'>
              <form className='sm:flex'>
                <label htmlFor='email-address' className='sr-only'>
                  Email address
                </label>
                <input
                  id='email-address'
                  name='email-address'
                  type='email'
                  autoComplete='email'
                  required
                  className='placeholder-warm-gray-500 w-full rounded-md border-white px-5 py-3 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-brandPrimaryDark'
                  placeholder='Enter your email'
                />
                <button
                  type='submit'
                  className='mt-3 flex w-full items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-brandPrimary duration-300 ease-in-out hover:scale-110 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2 focus:ring-offset-brandPrimaryDark sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0'
                >
                  Sign Up
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
