import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import PageTopper from '../../components/sections/Services/PageTopper';
import image from '../../images/service_cards/integral_card.png';
import infographicImage from '../../images/service_pages/integral/integral_infographic.webp';
import HeadingParagraphImage from '../../components/sections/Services/Integral/HeadingParagraphImage';
import ImageLeftTextRight from '../../components/sections/Services/ImageLeftTextRight';
import ImageRightTextLeft from '../../components/sections/Services/ImageRightTextLeft';
import PcInfographic from '../../components/sections/Services/Integral/PcInfographic';
import computerImage from '../../images/service_pages/integral/computer.jpg';
import gradesImage from '../../images/service_pages/integral/grades.png';

export default function Integral() {
  const heading = 'The Result?';
  const paragraph = `You can see and analyze the data concerning a student, cohort, or even the district all at once! Polaris Education connects to your local SIS, state SIS, and other accounts for no-hassle loading. With all of the data in one place, you can use Polaris to search and analyze data across data sources. Questions that used to turn into lengthy projects, such as “what is the average state reading score of students absent more than 80% of the time and not enrolled in support?” are now just a click away.`;
  return (
    <main>
      <NavBar />
      <PageTopper
        heading={'Complete'}
        headingSpan={'Data Integration'}
        paragraph={
          'Take your culture of analytics to the next level with Integral, our service for bringing together all the data your team uses, no matter where it comes from or how it’s stored.'
        }
        image={image}
        imageAlt={'Integral Logo'}
      />
      <ImageLeftTextRight
        image={infographicImage}
        imageAlt={'Woman using Polaris Education website on a laptop'}
        heading={'See the Complete Picture'}
        paragraphOne={`Our proprietary data pipelining processes allow you to bring all of your data sources into 
                      a single source of truth, so educators can use the tools that make the most sense for them.`}
        paragraphTwo={`Meanwhile, staff and administrators can cross-reference data from different places instantly, and see the complete picture without a series of spreadsheets and repetitive data manipulation projects.`}
        paragraphOneClasses={'mb-10'}
        paragraphTwoClasses={''}
        containerClasses={'mx-auto mt-20 lg:mt-24 py-20 lg:py-32 bg-gray-100'}
        imageContainerClasses={'max-w-xl lg:mr-10 lg:max-w-none'}
        imageClasses={'rounded-3xl shadow-xl'}
        flexContainerClasses={
          'flex flex-col items-center justify-between lg:flex-row'
        }
      />
      <HeadingParagraphImage
        image={computerImage}
        imageAlt={'Infographic of the Integral process'}
        margins={'mb-0'}
        heading={heading}
        paragraph={paragraph}
      />
      <ImageRightTextLeft
        image={gradesImage}
        imageAlt={'Grades and charts example'}
        heading={'Clear and Precise Charts & Graphs'}
        paragraphOne={`View a summary of the student’s data such as GPA over the years, their historical grades 
                      organized by letter grade, as well as their full report, broken down by year, course, and quarter/trimester.`}
        paragraphTwo={''}
        paragraphOneClasses={''}
        paragraphTwoClasses={'hidden'}
        containerClasses={'mx-auto mt-6 md:mt-10 bg-gray-100 py-20 lg:py-32'}
        imageContainerClasses={'max-w-xl lg:ml-10 lg:max-w-none'}
        imageClasses={'rounded-3xl shadow-xl max-h-[525px]'}
        flexContainerClasses={
          'flex flex-col items-center justify-between lg:flex-row'
        }
      />
      <PcInfographic />
      <Footer />
    </main>
  );
}
