import divider from '../../../images/dividers/home_testimonials_divider.png';
import DividerWithHeading from '../../DividerWithHeading';

export default function Testimonials() {
  return (
    <section className='mx-auto mt-16 max-w-8xl px-4 sm:px-6 lg:mt-32 lg:px-8'>
      <DividerWithHeading
        image={divider}
        heading={'Testimonials'}
        paragraph={'What our clients have to say.'}
      />
      <div className='mt-10 flex flex-col lg:flex-row'>
        <div className="mr-14 flex w-full bg-[url('images/home_testimonial_1.webp')] bg-cover bg-center">
          <div className='testimonial-overlay-1 flex h-full w-full bg-gray-500 bg-opacity-70'>
            <div className='max-w-[350px] pt-20 pb-3 sm:max-w-[400px]'>
              <p className='px-10 text-center text-lg font-medium leading-10 text-white'>
                <span className='quotations'>&quot;</span>The team at Polaris
                Education have been highly accommodating to any data request
                we&apos;ve had and they are very easy people with whom to work.
                As educators themselves, they are able to communicate with
                teachers and administrators using easy-to-understand language,
                thus "demystifying" the complexity of educational data.
              </p>
              <div className='-mt-8 flex w-full justify-end px-10'>
                <p className='font-medium text-white'>
                  <span className='quotations'>&quot;</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 flex w-full bg-[url('images/home_testimonial_2.webp')] bg-cover bg-center lg:mt-0">
          <div className='testimonial-overlay-2 flex h-full w-full justify-end bg-gray-500 bg-opacity-70'>
            <div className='max-w-[350px] pt-20 pb-3 sm:max-w-[400px]'>
              <p className='px-10 text-center text-lg font-medium leading-10 text-white'>
                <span className='quotations'>&quot;</span>We have been using
                Polaris Education for the past few years to provide student
                growth data for certified staff evaluations and program
                evaluation. They utilize several of our complicated data pieces,
                make it understandable for our staff, and provide a more
                complete picture of student achievement.
              </p>
              <div className='-mt-8 flex w-full justify-end px-10'>
                <p className='font-medium text-white'>
                  <span className='quotations'>&quot;</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
