import PostAuthor from './PostAuthor';
import authorImage from '../../../images/dave.webp';
import postImage from '../../../images/blog/blog_post_eight.webp';

export default function PostOne() {
  return (
    <div className='relative mt-16 overflow-hidden bg-white px-2 sm:px-6 lg:mt-32 lg:px-8'>
      <div className='hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full'>
        <div
          className='relative mx-auto h-full max-w-prose text-lg'
          aria-hidden='true'
        >
          <svg
            className='absolute top-12 left-full translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='74b3fd99-0a6f-4271-bef2-e80eeafdf357'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)'
            />
          </svg>
          <svg
            className='absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='f210dbf6-a58d-4871-961e-36d5016a0f49'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#f210dbf6-a58d-4871-961e-36d5016a0f49)'
            />
          </svg>
          <svg
            className='absolute bottom-12 left-full translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='d3eb07ae-5182-43e6-857d-35c643af9034'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#d3eb07ae-5182-43e6-857d-35c643af9034)'
            />
          </svg>
        </div>
      </div>
      <div className='relative px-4 sm:px-6 lg:px-8'>
        <div className='mx-auto max-w-prose text-lg'>
          <h1 className='text-center text-3xl font-bold tracking-tight text-brandText lg:text-4xl xl:text-5xl'>
            Expectations Are More{' '}
            <span className='text-brandPrimary'>Powerful</span> Than Points
          </h1>
          <p className='mt-8 text-xl leading-8 text-brandText'>
            Early one year, I handed back papers, and a student had received a
            5/10 on the assignment. She came to me and asked why she'd gotten
            half credit. When I told her she answered half the questions
            incorrectly she responded with genuine shock: "WHAT?! You're grading
            this for ACCURACY?!"
          </p>
        </div>
        <div className='prose prose-lg prose-indigo mx-auto mt-6 text-brandText'>
          <p>
            This remark is probably not uncommon to most teachers, and that is
            an indication of how widespread the practice of awarding "completion
            points" has become as well as all of their companion problems.
          </p>
          <p>
            Grades should be based upon mastery of content. Teachers sometimes
            have to make compromises for a variety of pragmatic reasons, many of
            which are beyond their control. Nevertheless, mastery of
            content—learning—must always be the ideal for which we strive.
          </p>
          <figure>
            <img
              className='w-full rounded-lg'
              src={postImage}
              alt=''
              width={1310}
              height={873}
            />
            {/* <figcaption>
              Taking time to build thoughtful data practices will have a big
              long-term impact.
            </figcaption> */}
          </figure>
          <h2 className='text-brandText'>
            What’s wrong with completion points
          </h2>
          <p>
            Let’s be real. This is a behavior management device. You give
            points, they do the work. In effect, the points are an incentive to
            do the work, right?
          </p>
          <p>Right?</p>
          <p>
            Used generally as a short-term motivator to get students to complete
            work, completion points have various unintended consequences on
            student behavior and learning, and even the logic with which they
            are defended is typically suspect. When pressed on the matter, most
            educators speak to the pragmatic use of completion points, not the
            educational value. Fair enough, but we must always keep pressure on
            the ideal and work to develop ways to make authentic teaching and
            learning more pragmatic.
          </p>
          <p>
            Points can be an incentive, but they are kind of a negative one when
            you think about it. We're not adding in something positive (hey
            everybody, it's points! YES!). When we assign points as the
            incentive to do the work, aren't we really implying a threat: I will
            lower your grade if you don't comply.
          </p>
          <p>
            What's more is the impact on student work. When we give them
            thoughtless work or treat their work thoughtlessly, they respond in
            kind. Can you think of some times in your school or (if we’re being
            honest) your work life where you maybe gave a little less because
            you thought nobody really cared enough to check anyway? If they know
            it's just for completion, most students won't work as hard as they
            would if they knew you'd grade it for accuracy. Why give the
            assignment if we're going to immediately undermine it by not seeing
            if it's correct? Why should a student even try? (The "love of
            learning" argument, a standard nobody imposes on themselves, is not
            admissible here. We love to learn the things we already love, but
            how many people are pursuing the deep study of something they are
            not interested in purely for this “love” to exercise their brain. )
            If they have to choose where to commit their time and energy,
            shouldn't they commit it where it matters? If accuracy doesn't
            matter to you, why would it matter to them?
          </p>
          <h2 className='text-brandText'>
            Teachers want their students to learn and be successful. So why are
            completion points given?
          </h2>
          <p>
            Teachers enter the field because they want to work with students and
            they enjoy teaching. This is not hyperbole, it’s fact. Here are some
            of the most common reasons I’ve heard while working with educators.
          </p>
          <ul>
            <li>We need to create defensible report card grades</li>
            <li>
              If we don’t give points, students stop doing it. We don’t have
              time to grade all of the work.
            </li>
            <li>
              We don’t need to check that students know every last little thing
              on an assignment.
            </li>
            <li>
              We feel a need to give students that don’t test well an “out,” so
              they can still pass.
            </li>
          </ul>
          <p>
            The problem is that completion points fail to incentivize hard work
            and mastery. What gets measured gets done. When assignments are
            assessed for their completeness, completeness is what gets done.
            Some of the consequences that occur, regardless of the instructor’s
            intent:
          </p>
          <ul>
            <li>
              Students catch on quickly about whether or not work is graded for
              mastery. If not, the effort dwindles dramatically.
            </li>
            <li>
              The grade becomes about turning in work, not mastery. We give the
              impression that all that really matters is that it’s done. This
              leads to less preparation, lower performance.
            </li>
            <li>
              We create problems for ourselves later: “Well, my daughter’s
              getting A’s on all the assignments but failing all the tests.
              Why?” Though it’s not the only source, this practice feeds into
              the attitude that “I’m just not a good test taker.” It’s easy to
              see how students become confused about their learning. Are they
              getting consistent, clear signals about what they know/don’t know
              if the grade is based on merely completing the assignment?
            </li>
            <li>
              If the assignment is valuable to learning the content, we want the
              students to give their best effort. Completion points engenders
              compliance behaviors, not effort in learning.
            </li>
            <li>
              If the instructor is not willing to even read it, seriously, why
              SHOULD they work hard on it. In short, kids live up to, or at
              least trend toward, our expectations. If an assignment is worth
              basing their grade on (in all or in part), then by definition it
              warrants the instructor’s attention.
            </li>
          </ul>
          <h2 className='text-brandText'>
            Expectations are more powerful than points
          </h2>
          <p>
            The most powerful alternative to points are expectations. Take the
            conversation out of the extrinsic/punitive realm and keep it the
            learning realm with expectations and some of the strategies listed
            below. Standing alone, all of these combined could not completely
            resolve the problems that lead to teachers employing completion
            points. That said, a well-implemented combination of these will
            increase student productivity and help to mitigate some of the
            apathy and lack of effort that are often the impetus for points, and
            the current system is not without problems or flaws. These are
            centered on providing individual attention/feedback and student
            engagement in the learning process. This is not an exhaustive list,
            nor is it thought that these concepts are universally applicable to
            all circumstances.
          </p>
          <h2 className='text-brandText'>
            Provide feedback, but don’t grade some assignments.
          </h2>
          <p>
            If you’re just looking for a handful of key points or concepts,
            consider just asking for those or just marking those incorrect.
            Students get the feedback, but not points. It’s OK to give work back
            to students with feedback but no grade. “Your thesis was clearly
            defined, but you need additional facts in support of it.” The
            feedback is the key thing they need anyway, and this can be achieved
            without involving points.
          </p>
          <p>
            Showing an interest and knowledge about the student's proficiency
            would be a really good way to do this. First, it reinforces the
            value of the assignment. "I DO care that you do this right." Second,
            it reinforces the relationship between you and the student. That
            relationship is a critical part of their motivation. It's not just a
            matter of building rapport so they'll work hard for you, but more to
            the point that all of us work harder when we feel valued. Hawthorne
            Studies, anybody?
          </p>
          <p>
            The nice thing about feedback is that it's best delivered in person,
            and this means less time spent grading after school instead of being
            with your family. Have them take out last night's homework, give
            them something to work on, and spend the same amount of time per kid
            looking at their paper and talking with them, and you'll teach them
            more than 8/10 completion points ever could as well as show them
            that you actually care enough to steer them in the right direction.
            In addition, the social pressure of facing you when they don't have
            the work is helpful. Here's how I usually dealt with these:
          </p>
          <p>"Hi Gabe, let's take a look at the work."</p>
          <p>"Uh...I don't have it."</p>
          <p>"What happened? Is everything OK?"</p>
          <p>
            (Tone is key. Give an inch while pretending to give a mile. I tried
            to convey that I was SO surprised the work wouldn't get done that
            something awful must have happened that warranted my concern not
            anger. And that's even more leverage--because students don't feel
            attacked. Almost without fail I'd get the truth: "Yeah, everything's
            fine. I just didn't do it cuz…."
          </p>
          <p>
            When students didn't have the work done, I recorded that. If it was
            habitual, time to call Mom and Dad.
          </p>
          <h2 className='text-brandText'>Coach</h2>
          <p>
            Instead of collecting the homework, while the class is working
            independently, circulate to students and discuss their work with
            them. This is one of clearest ways to teach and coach and provide
            direct instruction.
          </p>
          <h2 className='text-brandText'>Collect the work directly</h2>
          <p>
            Rather than having students just pass in work, take it from them
            directly. They have to look you in the eye and tell you they haven’t
            done it. It escalates the seriousness of the assignment (and not
            doing it), and at the very least it alerts you to potential issues.
          </p>
          <p>
            Record when students do/do not complete the work, but don’t give
            points either way. You can do the same for assignments submitted
            late. The “late/missing” assignments will support you in a
            conversation about why the student did poorly. Keeping these records
            would probably take less time in the long run than all the
            paper-pushing and data entry of a quarter’s worth of completion
            points.
          </p>
          <h2 className='text-brandText'>Call their parents</h2>
          <p>
            The punishment for not doing the work should be doing the work, and
            this is probably the most direct method of achieving that. There are
            many ways to reach out, but direct personal contact is still the
            best, and the digital methods simply are not a substitute. It can be
            a bear to call them, especially early on, but effort early tends to
            pay off later. If nothing else, the teacher taking the time to make
            a phone call (especially in this day/age) sends a signal of
            importance and urgency. Also, consider homework detentions, though
            it’s likely more efficient to make the phone calls and thereby get
            the parents to supervise HW detention at home.
          </p>
          <h2 className='text-brandText'>Call their parents again</h2>
          <p>
            It may take several attempts. And here is probably a good place to
            remark that’s a good idea to call parents on a regular basis either
            way. Each call is a deposit into the emotional bank account.
            Especially in the case of struggling students, parents don’t often
            get positive calls home. If you are the only teacher that’s ever
            called to say what the kid does right, mom is way more likely to
            listen when you call for less pleasant reasons. Remember, parents
            need to know you care too.
          </p>
          <h2 className='text-brandText'>
            Shoot for the most directly relevant work wherever possible
          </h2>
          <p>
            Think of the students who LOVE your class or have a personal
            interest in your content area. They are generally motivated already.
            It won’t be possible for all students or all circumstances, but the
            more we can find ways to make them see the relevance in the work,
            the more they will complete it. Beyond tapping into personal
            interest/passion, another practical way to make work relevant is to
            make it as similar to the summative assessment as possible. For all
            the hype around things like having students “write riddles about
            vocab terms,” etc., sometimes kids are more engaged by an assignment
            that comes with the caveat, “The exam will look almost just like
            this.” Challenging them with near matches to the summative
            assessment earlier in the unit gets them thinking about the end
            product and how their progress measures up.
          </p>
          <h2 className='text-brandText'>Relationship</h2>
          <p>
            Know your students. When the student is convinced that their teacher
            cares and is interested in helping them individually, they tend to
            respond, and sometimes the more challenging students, once won over,
            respond to this the most. It takes some time to build, but can be as
            easy as making small talk before class begins, etc. Be genuine.
          </p>
          <h2 className='text-brandText'>
            Give an ungraded assignment immediately followed by a quiz.
          </h2>
          <p>
            Keep doing this to help them make the link between completing
            practice and performing well on assessments. Build up toward larger
            assessments or longer periods of time.
          </p>
          <h2 className='text-brandText'>Do not give the assignment at all.</h2>
          <p>
            Seriously. If you aren't going to make sure it's done right, why ARE
            you giving it? Presumably the answer is that it's worthwhile if the
            kids do it. But since we can all accept the reality now that they
            don't do it well for completion points, that argument is moot.
            Generally, I don't think this is the best alternative to pursue over
            completion points, but I think it's worth asking the question of
            ourselves periodically, and in some cases maybe we wouldn't give the
            assignment after consideration. What I'm really getting at here is
            maybe you don't have to give and grade so many assignments. You
            should be grading enough to justify signal the student about their
            mastery level and justify the report card grade. After that, your
            time is best spent assessing and assisting students.
          </p>
          <h2 className='text-brandText'>
            Increase the weight of assessments, decrease the value of homework
          </h2>
          <p>
            This alleviates some of the fairness/ethical questions connected
            with letting kids earn points through redoing work, not giving 0s,
            etc. If the HW has a very small grade impact, students can practice
            in a penalty free environment, and they can (must) ultimately focus
            on assessments. The students do adjust to high assessment weights,
            though it may take some effort to get them acclimated.
          </p>
          <p>
            Again, there’s no expectation that, even with perfect
            implementation, that any or all of these combined can make students
            do their work all the time. What I can say about these suggestions
            is that I've seen them have as much impact as points and equally
            important, they are consistent with the values held by a good
            teacher.
          </p>
          <p>
            Difficult as it is, our role as educators is to always push for
            what’s educationally appropriate for the individual student.
            Completion points speak to the masses…and teach them to be dumb and
            compliant.
          </p>
          <p>
            As a last point to underscore that point, an experience in my class
            one year comes to mind. It was one of the first few days of the
            course, and I had passed back the first assignment of the year, a
            largely open-ended questionnaire about students’ backgrounds. One
            student, a junior, came up to me after class and said,
          </p>
          <p>
            "Mr. Kush, I worked really hard on this, and can't believe you'd
            write something so mean and sarcastic on my paper."
          </p>
          <p>
            He was polite and respectful, but clearly was hurt. What did I write
            on the student's paper to prompt the remark I began?
          </p>
          <p>
            “Thank you for putting in so much time and effort. There’s a ton of
            great details here about your background.”
          </p>
          <p>
            Authentic, genuine feedback was so rare in this student’s (and many
            students’) education that when he first encountered it he thought it
            was an attempt to sarcastically belittle him. Of course, it was a
            relief for us both to clear it up, and there was another benefit. I
            DID grade the assignment. He had received “full credit” but didn’t
            even notice it. The feedback was far more important both before and
            after we cleared it up.
          </p>
          <p>
            Expectations, feedback, and care are more powerful than points, and
            they allow us to have the right conversations about learning.
          </p>
          <PostAuthor
            image={authorImage}
            name={'David Kush'}
            title={'Managing Director'}
          />
        </div>
      </div>
    </div>
  );
}
