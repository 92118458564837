export default function Heading({ heading }) {
  return (
    <div className='mx-auto mt-16 max-w-8xl px-0 sm:px-6 lg:mt-32 lg:px-8'>
      <div className='mx-auto'>
        <div className='flex flex-col items-center px-4 sm:px-6 lg:px-8'>
          <h1 className='text-center text-3xl font-extrabold tracking-tight text-brandText md:text-4xl lg:text-5xl'>
            {heading}
          </h1>
        </div>
      </div>
    </div>
  );
}
