export default function HeadingWithParagraph({ heading, paragraph }) {
  return (
    <div className='mx-auto mt-16 max-w-8xl px-0 sm:px-6 lg:mt-32 lg:px-8'>
      <div className='mx-auto'>
        <div className='flex flex-col items-center px-4 sm:px-6 lg:px-8'>
          <h1 className='text-center text-3xl font-extrabold tracking-tight text-brandText md:text-4xl lg:text-5xl'>
            {heading}
          </h1>
          <p className='mt-3 max-w-prose text-center text-lg text-brandText lg:text-xl'>
            {paragraph}
          </p>
        </div>
      </div>
    </div>
  );
}
