import { Link } from 'react-router-dom';

export default function BlueCTA({ margins }) {
  return (
    <div
      className={`flex items-center justify-center bg-gradient-to-r from-brandPrimary to-brandPrimaryDark ${margins}`}
    >
      <div className='my-16 mx-auto flex flex-col items-center px-4 sm:px-6 lg:px-8'>
        <div className='flex flex-col items-center'>
          <h2 className='max-w-prose text-center text-3xl font-extrabold tracking-tight text-white'>
            Want to see the Polaris solution in action?
          </h2>
          <p className='mt-3 max-w-prose py-3 text-center text-lg font-medium text-white'>
            We believe no child should be left behind. Request a live demo to
            see the Polaris solution in action and start taking advantage of
            your school&apos;s data sources today!{' '}
          </p>
        </div>
        <div className='flex w-full'>
          <Link
            className='mx-auto mt-5 inline-flex items-center justify-center whitespace-nowrap rounded-md border-2 border-transparent bg-white px-5 py-3 text-lg font-bold text-brandPrimary shadow-sm duration-300 ease-in-out hover:scale-110 hover:bg-gray-100'
            to='/contact'
          >
            Request a Demo
          </Link>
        </div>
      </div>
    </div>
  );
}
