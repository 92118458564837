import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import PasswordReset from './pages/PasswordReset';
import ScrollToTop from './ScrollToTop';
import Funding from './pages/Funding';
import Contact from './pages/Contact';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Book from './pages/Book';
import ThankYou from './pages/ThankYou';

/* importing blog pages */
import BlogHomePage from './pages/BlogHomePage';
import BlogPostOne from './pages/blogPosts/BlogPostOne';
import BlogPostTwo from './pages/blogPosts/BlogPostTwo';
import BlogPostThree from './pages/blogPosts/BlogPostThree';
import BlogPostFour from './pages/blogPosts/BlogPostFour';
import BlogPostFive from './pages/blogPosts/BlogPostFive';
import BlogPostSix from './pages/blogPosts/BlogPostSix';
import BlogPostSeven from './pages/blogPosts/BlogPostSeven';
import BlogPostEight from './pages/blogPosts/BlogPostEight';
import BlogPostNine from './pages/blogPosts/BlogPostNine';

/* importing services pages */
import Integral from './pages/services/Integral';
import Insight from './pages/services/Insight';
import Compass from './pages/services/Compass';
import Lighthouse from './pages/services/Lighthouse';
import Adviser from './pages/services/Adviser';
import Adapt from './pages/services/Adapt';

/* importing Resource pages*/
import ResourcesHomePage from './pages/ResourcesHomePage';
import CommunicatingDataResources from './pages/resources/CommunicatingDataResources';
import StandardsBasedResources from './pages/resources/StandardsBasedResources';
import MtssResources from './pages/resources/MtssResources';
import ToolkitResources from './pages/resources/ToolkitResources';
import UseOfDataResources from './pages/resources/UseOfDataResources';
import EquityDataResources from './pages/resources/EquityDataResources';
import TrackingSuccessResources from './pages/resources/TrackingSuccessResources';

export default function App() {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path={'/'} element={<Home />} />
          <Route path={'/about'} element={<About />} />
          <Route path={'/funding'} element={<Funding />} />
          <Route path={'/contact'} element={<Contact />} />
          <Route path={'/book'} element={<Book />} />
          <Route path={'/login'} element={<Login />} />
          <Route path={'/auth/password-reset'} element={<PasswordReset />} />
          <Route path={'/thank-you'} element={<ThankYou />} />

          {/* routes for blog pages */}
          <Route path={'/blog'} element={<BlogHomePage />} />
          <Route path={'/blog/mtss_data_problem'} element={<BlogPostOne />} />
          <Route path={'/blog/data_driven_pt1'} element={<BlogPostTwo />} />
          <Route path={'/blog/data_driven_pt2'} element={<BlogPostThree />} />
          <Route path={'/blog/data_driven_pt3'} element={<BlogPostFour />} />
          <Route
            path={'/blog/real-world-tasks-in-assessments'}
            element={<BlogPostFive />}
          />
          <Route
            path={
              '/blog/things-every-school-leader-should-know-about-leading-with-data'
            }
            element={<BlogPostSix />}
          />
          <Route path={'/blog/gap-thinking'} element={<BlogPostSeven />} />
          <Route
            path={'/blog/expectations-are-more-powerful-than-points'}
            element={<BlogPostEight />}
          />
          <Route
            path={'/blog/what-gets-measured-gets-done'}
            element={<BlogPostNine />}
          />

          {/* routes for services pages */}
          <Route path={'/integral'} element={<Integral />} />
          <Route path={'/insight'} element={<Insight />} />
          <Route path={'/lighthouse'} element={<Lighthouse />} />
          <Route path={'/compass'} element={<Compass />} />
          <Route path={'/adviser'} element={<Adviser />} />
          <Route path={'/adapt'} element={<Adapt />} />

          {/* routes for resources pages */}
          <Route path={'/resources'} element={<ResourcesHomePage />} />
          <Route
            path={'/resources/mtss-resources'}
            element={<MtssResources />}
          />
          <Route
            path={'/resources/standards-based-instruction-teaching-resources'}
            element={<StandardsBasedResources />}
          />
          <Route
            path={'/resources/toolkit-resources'}
            element={<ToolkitResources />}
          />
          <Route
            path={'/resources/use-of-data-resources'}
            element={<UseOfDataResources />}
          />
          <Route
            path={'/resources/tracking-success-of-programming-resources'}
            element={<TrackingSuccessResources />}
          />
          <Route
            path={'/resources/equity-and-data-resources'}
            element={<EquityDataResources />}
          />
          <Route
            path={
              '/resources/communicating-data-and-goals-with-staff-resources'
            }
            element={<CommunicatingDataResources />}
          />

          {/* 404 page */}
          <Route path={'*'} element={<NotFound />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}
