import PostAuthor from './PostAuthor';
import authorImage from '../../../images/dave.webp';
import postImage from '../../../images/blog/blog_post_nine.webp';

export default function PostNine() {
  return (
    <div className='relative mt-16 overflow-hidden bg-white px-2 sm:px-6 lg:mt-32 lg:px-8'>
      <div className='hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full'>
        <div
          className='relative mx-auto h-full max-w-prose text-lg'
          aria-hidden='true'
        >
          <svg
            className='absolute top-12 left-full translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='74b3fd99-0a6f-4271-bef2-e80eeafdf357'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)'
            />
          </svg>
          <svg
            className='absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='f210dbf6-a58d-4871-961e-36d5016a0f49'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#f210dbf6-a58d-4871-961e-36d5016a0f49)'
            />
          </svg>
          <svg
            className='absolute bottom-12 left-full translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='d3eb07ae-5182-43e6-857d-35c643af9034'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#d3eb07ae-5182-43e6-857d-35c643af9034)'
            />
          </svg>
        </div>
      </div>
      <div className='relative px-4 sm:px-6 lg:px-8'>
        <div className='mx-auto max-w-prose text-lg'>
          <h1 className='text-center text-3xl font-bold tracking-tight text-brandText lg:text-4xl xl:text-5xl'>
            What Gets <span className='text-brandPrimary'>Measured </span> Gets
            <span className='text-brandPrimary'> Done</span>
          </h1>
          <p className='mt-8 text-xl leading-8 text-brandText'>
            When it comes to improving student learning, one of the most
            critical areas for teachers to address is classroom assessment
            policy. Classroom assessment practices, more than any other
            component of teaching, have been heavily influenced by pragmatism.
            The negative effects of the factory school system imported from
            Europe to the United States are particularly evident in the
            combination of large class sizes and the need for consistent
            record-keeping. Unfortunately, this has led to a reductionist
            approach to assessment, where it is primarily seen as grading
            assignments rather than a comprehensive evaluation of learning.
          </p>
        </div>
        <div className='prose prose-lg prose-indigo mx-auto mt-6 text-brandText'>
          <figure>
            <img
              className='w-full rounded-lg'
              src={postImage}
              alt=''
              width={1310}
              height={873}
            />
            {/* <figcaption>
              Taking time to build thoughtful data practices will have a big
              long-term impact.
            </figcaption> */}
          </figure>
          <p>
            While technology has the potential to alleviate some of these
            challenges, it can also exacerbate the situation if not used
            carefully. The advantage of using technology for grading lies in its
            ability to save teachers time by automatically calculating final
            grades. Teachers can input grades as they go, and the system
            maintains a running total that is frequently updated. Parents can
            easily log in and monitor their children's progress. This practice
            not only saves teachers from the repetitive task of calculating
            grades but also reduces the need for frequent phone calls between
            teachers and parents.
          </p>
          <p>
            However, we must pause and reflect on whether this practice is
            ideal. Has it primarily evolved due to its practicality rather than
            its educational value? Has the advent of digital gradebooks shifted
            the focus to the wrong aspect—grades instead of learning? I believe
            so.
          </p>
          <p>
            Let me share my personal experience as a parent with two children in
            different educational stages. When my younger child was in
            elementary school, the teachers used grading software, but we also
            received a weekly folder containing all of his work from the
            previous week. We would carefully review each assignment, discussing
            both the strengths and areas needing improvement. Most importantly,
            if there was a concern, we identified it immediately and delved into
            it further. For example, one week during spring, we realized that he
            struggled with improper fractions. We promptly worked on it
            together, providing additional practice and support. This immediate
            feedback allowed for quick adjustments and personalized assistance
            tailored to his specific needs.
          </p>
          <p>
            However, the conversation surrounding assessment changed when my
            older child entered high school. It became a different dynamic
            altogether. "You received a 2 out of 10 on 'Practice 5.' What
            happened?" It was challenging to pinpoint the exact issue without
            reviewing the actual work. Although it is possible to achieve the
            same level of engagement as in the previous example, it would
            require significantly more effort. More importantly, grading in this
            manner implies that the final grade is the most, if not the only,
            important aspect. As the saying goes, "What gets measured gets
            done." Students inevitably focus on the final grade rather than the
            learning process itself.
          </p>
          <p>
            Grades serve as a summary of learning, but gradually they have
            infiltrated our perception of the purpose of education, shifting the
            emphasis from mastery to achievement. While our current grading
            practices have saved us time, they have also altered the mindset of
            students, teachers, and parents, diverting attention away from
            thoughtful qualitative discussions about learning and redirecting it
            toward mechanistic systems of reporting. This shift comes at a cost,
            impacting actual student learning and potentially hindering the
            development of well-rounded individuals who truly engage with
            education beyond mere achievement.
          </p>
          <p>
            Whether we are educators or parents, we must carefully consider what
            we choose to measure and emphasize in our educational endeavors. Our
            focus should be on measuring and reporting learning—the continuous
            and formative progress of students—rather than solely relying on
            summative marks.
          </p>
          <p>
            To achieve this, we need to move beyond traditional grading
            practices and embrace a more holistic approach to assessment.
            Instead of fixating on a single letter or number that represents the
            final outcome, we should encourage students to reflect on their
            growth, identify areas for improvement, and take ownership of their
            learning journey. This can be accomplished by incorporating a
            variety of assessment methods that go beyond simply grading
            assignments.
          </p>
          <p>
            Project-based assessments, for instance, allow students to
            demonstrate their knowledge and skills through real-world
            applications. By engaging in hands-on projects, they not only
            showcase their understanding of the subject matter but also develop
            critical thinking, problem-solving, and collaboration skills.
            Portfolio reviews provide a comprehensive overview of a student's
            progress over time, highlighting their strengths and areas needing
            improvement. Peer evaluations foster a sense of shared
            responsibility and promote a growth mindset, as students learn from
            one another's feedback and perspectives.
          </p>
          <p>
            By prioritizing ongoing assessments that provide meaningful
            feedback, we create an environment where students can thrive. We
            shift the focus from the final grade to the learning process itself,
            nurturing a deep and genuine understanding of the subject matter.
            This encourages students to become active participants in their own
            education, fostering curiosity, creativity, and a lifelong love of
            learning.
          </p>
          <p>
            As educators and parents, we have a responsibility to carefully
            consider the impact of our assessment practices on student learning.
            We must resist the temptation to reduce assessment to mere grading
            and embrace a more comprehensive and formative approach. By shifting
            the focus from grades to learning, we can truly empower our students
            to reach their full potential and become lifelong learners who are
            prepared to tackle the challenges of the future.
          </p>
          <PostAuthor
            image={authorImage}
            name={'David Kush'}
            title={'Managing Director'}
          />
        </div>
      </div>
    </div>
  );
}
