import studentReportImage from '../../../../images/service_pages/integral/student_report.png';

export default function PcInfographic() {
  return (
    <section className='mx-auto my-16 text-center lg:my-32'>
      <div className='mx-auto max-w-8xl px-4 sm:px-6 lg:px-8'>
        <div className='mb-12'>
          <h2 className='mx-auto max-w-5xl text-center text-3xl font-extrabold tracking-tight text-brandPrimary md:text-4xl lg:text-5xl'>
            Integral Data Integration Reading Scores/Information:
          </h2>
        </div>
        <div className='mx-auto sm:px-5 md:px-10 lg:max-w-4xl lg:px-0 xl:max-w-5xl'>
          <img
            src={studentReportImage}
            alt='Infographic of Polaris page showing visualized data'
          />
        </div>
        <div className='mx-auto mt-12 max-w-4xl'>
          <h2 className='text-center text-3xl font-extrabold tracking-tight text-brandText md:text-4xl lg:text-5xl'>
            Single Source of Truth
          </h2>
          <p className='mt-3 text-center text-lg text-brandText lg:text-xl'>
            Integral brings you all of the data you need in one place so that
            the entire team can collaborate to make the best use of it. Beyond
            the time saved by not having to compile the data, teams are also
            able to work faster. They can see everything, all compiled in one
            simple screen using state-of-the-art graphs and tables created
            through our software. No time is wasted comparing numbers from
            different people, reports, or data sources, trying to discern what’s
            going on. Move quickly from insight to action that benefits
            students.
          </p>
        </div>
      </div>
    </section>
  );
}
