import Footer from '../components/Footer';
import GrayCTA from '../components/GrayCTA';
import HeadingWithParagraph from '../components/HeadingWithParagraph';
import NavBar from '../components/NavBar';
import InfographicOne from '../components/sections/About/InfographicOne';
import InfographicTwo from '../components/sections/About/InfographicTwo';
import PageTopper from '../components/PageTopper';
import mobileImage from '../images/about_hero_mobile.webp';

export default function About() {
  return (
    <main>
      <NavBar />
      <PageTopper
        imageUrl="bg-[url('images/about_hero.webp')]"
        mobileImage={mobileImage}
        pageTitle={'About Polaris'}
        heading={'For Educators'}
        headingSpan={'By Educators'}
        paragraph={
          'Polaris is a site for educators to better understand their students and respond to individual needs.  It was developed and is maintained by a team of educators who curate the content and applications.'
        }
      />
      <HeadingWithParagraph
        heading={'The Polaris Solution'}
        paragraph="Educators need data to do the best job possible. We need to know we're focusing on the right problems, our solutions are working, and most importantly--and challenging-- none of our students slip through the cracks. In recent years however, the problem isn't really having data--it's just as often having too much, outdated, or disjointed data, or multiple versions and sources.
        "
      />
      <InfographicOne />
      <InfographicTwo />
      <GrayCTA margins={'mt-16 lg:mt-32'} />
      <Footer />
    </main>
  );
}
