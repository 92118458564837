import { Link } from 'react-router-dom';
import blogPostImage from '../../../images/blog/post_four.webp';

const posts = [
  {
    title: 'Is Your MTSS Problem Really a Data Problem?',
    href: '/blog/mtss_data_problem',
    category: { name: 'Article' },
    description:
      'Now more than ever, schools are inundated with struggling students. From learning loss, to social emotional disorders, and more, MTSS teams are finding themselves increasingly overwhelmed by the task at hand.',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    imageUrl:
      'https://images.unsplash.com/photo-1509062522246-3755977927d7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    readingTime: '6 min',
    author: {
      name: 'David Kush',
      imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    title: 'Data Driven Schools (Part 1)',
    href: '/blog/data_driven_pt1',
    category: { name: 'Article' },
    description:
      'The phrase data driven gets thrown around in education so often it can start to sound empty and hollow. What does that phrase really mean? To say an organization is data driven is really just making a statement about the larger Culture of Analytics (COA). Every org has a COA, it’s just a matter of what statements can be made about it.',
    date: 'Mar 10, 2020',
    datetime: '2020-03-10',
    imageUrl:
      'https://images.unsplash.com/photo-1546410531-bb4caa6b424d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    readingTime: '4 min',
    author: {
      name: 'Tim Laba',
      imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    title: 'Data Driven Schools (Part 2)',
    href: '/blog/data_driven_pt2',
    category: { name: 'Article' },
    description:
      "In part one we addressed the importance of a strong data foundation to level-up your culture of analytics. Put simply, the data has to be accurate and available. That said, unless your staff knows what to do with the data, it's useless. Making sure people know how to marshall data for action is process that takes time to do right. With that said, here some steps you can to improve your staff's capacity with data.",
    date: 'Feb 12, 2020',
    datetime: '2020-02-12',
    imageUrl:
      'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    readingTime: '11 min',
    author: {
      name: 'Brian Adams',
      imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    title: 'Data Driven Schools (Part 3)',
    href: '/blog/data_driven_pt3',
    category: { name: 'Article' },
    description:
      'Part I of this series looked at how to set up your data infrastructure for success.  In Part II we examined some ways to better equip the staff to utilize data.  In this final part on being Data Driven, we will look at a few strategies that you can employ to build a strong culture of analytics.',
    date: 'Feb 12, 2020',
    datetime: '2020-02-12',
    imageUrl: blogPostImage,
    readingTime: '11 min',
    author: {
      name: 'Brian Adams',
      imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    title: 'Real-World Tasks in Assessments',
    href: '/blog/real-world-tasks-in-assessments',
    category: { name: 'Article' },
    description:
      'An often repeated phrase by teachers in classrooms across America is, “they won’t take that in the real world!”  It’s advice that, as educators, we need to take as well.  After all, the purpose of school isn’t test scores, grades, and/or standards for their own sake. It’s to prepare students for the real world they are going to encounter.',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    imageUrl:
      'https://images.unsplash.com/photo-1606326608690-4e0281b1e588?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    readingTime: '6 min',
    author: {
      name: 'David Kush',
      imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    title: 'Things Every School Leader Should Know About Leading with Data',
    href: '/blog/things-every-school-leader-should-know-about-leading-with-data',
    category: { name: 'Article' },
    description:
      "There are more administrators in education than ever, and most of them are being charged with affecting some sort of change in their district, typically data driven change.  But if your school or district isn't already firing on all cylinders, there's a couple things you need to know as you head down the path.",
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    imageUrl:
      'https://images.unsplash.com/photo-1527685609591-44b0aef2400b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=700&q=80',
    readingTime: '6 min',
    author: {
      name: 'David Kush',
      imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    title: 'Gap Thinking',
    href: '/blog/gap-thinking',
    category: { name: 'Article' },
    description:
      'One way to help improve teaching and learning is to focus on Gap Thinking.  When students are not providing us with work at our expectation level, it is considered a deficiency or “gap” in what is desired vs. what is the student’s current mastery.',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    imageUrl:
      'https://images.unsplash.com/photo-1541178735493-479c1a27ed24?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=700&q=80',
    readingTime: '6 min',
    author: {
      name: 'David Kush',
      imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    title: 'Expectations Are More Powerful Than Points',
    href: '/blog/expectations-are-more-powerful-than-points',
    category: { name: 'Article' },
    description:
      'Early one year, I handed back papers, and a student had received a 5/10 on the assignment.  She came to me and asked why she\'d gotten half credit.  When I told her she answered half the questions incorrectly she responded with genuine shock: "WHAT?! You\'re grading this for ACCURACY?!"',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    imageUrl:
      'https://images.pexels.com/photos/7092342/pexels-photo-7092342.jpeg?auto=compress&cs=tinysrgb&w=700&dpr=2',
    readingTime: '6 min',
    author: {
      name: 'David Kush',
      imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
  {
    title: 'What Gets Measured Gets Done',
    href: '/blog/what-gets-measured-gets-done',
    category: { name: 'Article' },
    description:
      'When it comes to improving student learning, one of the most critical areas for teachers to address is classroom assessment policy. Classroom assessment practices, more than any other component of teaching, have been heavily influenced by pragmatism.',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    imageUrl:
      'https://images.unsplash.com/photo-1550985543-49bee3167284?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=700&q=80',
    readingTime: '6 min',
    author: {
      name: 'David Kush',
      imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },
];

export default function BlogPosts() {
  return (
    <div className='relative mt-12 bg-gray-100 px-4 pb-6 sm:px-6 lg:px-8'>
      <div className='absolute inset-0'>
        <div className='h-1/3 bg-white sm:h-2/3' />
      </div>
      <div className='relative mx-auto max-w-8xl'>
        <div className='mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3'>
          {posts.map((post) => (
            <div
              key={post.title}
              className='flex flex-col overflow-hidden rounded-lg shadow-lg'
            >
              <Link to={post.href}>
                <div className='flex-shrink-0'>
                  <img
                    className='h-48 w-full object-cover'
                    src={post.imageUrl}
                    alt=''
                  />
                </div>
              </Link>
              <div className='flex flex-1 flex-col justify-between bg-white p-6'>
                <div className='flex-1'>
                  <p className='text-sm font-medium text-brandPrimary'>
                    {post.category.name}
                  </p>
                  <Link to={post.href} className='mt-2 block'>
                    <p className='text-xl font-semibold text-brandText'>
                      {post.title}
                    </p>
                    {/* Remove the mb-5 when adding back the author section*/}
                    <p className='mt-3 mb-5 text-base text-brandText'>
                      {post.description}
                    </p>
                  </Link>
                </div>
                {/* <div className='mt-6 flex items-center'>
                  <div className='flex-shrink-0'>
                    <span className='sr-only'>{post.author.name}</span>
                    <img
                      className='h-10 w-10 rounded-full'
                      src={post.author.imageUrl}
                      alt=''
                    />
                  </div>
                  <div className='ml-3'>
                    <p className='text-sm font-medium text-brandText'>
                      {post.author.name}
                    </p>
                    <div className='flex space-x-1 text-sm text-gray-500'>
                      <time dateTime={post.datetime}>{post.date}</time>
                      <span aria-hidden='true'>&middot;</span>
                      <span>{post.readingTime} read</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
