export default function ImageRightTextLeft({
  image,
  imageAlt,
  heading,
  paragraphOne,
  paragraphTwo,
  paragraphOneClasses,
  paragraphTwoClasses,
  containerClasses,
  imageContainerClasses,
  imageClasses,
  flexContainerClasses,
}) {
  return (
    <section className={`${containerClasses} `}>
      <div className='mx-auto px-4 sm:px-6 lg:max-w-8xl lg:px-8'>
        <div className={`${flexContainerClasses} `}>
          <div className='mb-10 max-w-prose text-center text-lg text-brandText lg:mb-0 lg:max-w-2xl lg:text-xl'>
            <h2 className='mb-3 text-3xl  font-extrabold tracking-tight text-brandText md:text-4xl lg:mb-6 lg:text-5xl'>
              {heading}
            </h2>
            <p className={`${paragraphOneClasses} `}>{paragraphOne}</p>
            <p className={`${paragraphTwoClasses} `}>{paragraphTwo}</p>
          </div>
          <div className={`${imageContainerClasses} `}>
            <img src={image} className={`${imageClasses} `} alt={imageAlt} />
          </div>
        </div>
      </div>
    </section>
  );
}
