import dividerTopDesktop from '../../../../images/dividers/compass_clock_desktop.svg';
import dividerTopMobile from '../../../../images/dividers/compass_clock_mobile.svg';
import image from '../../../../images/service_pages/compass/compass_teacher_student.webp';
import ReactPlayer from 'react-player';

export default function SectionThree() {
  return (
    <section className={'mx-auto bg-white py-20 lg:py-32'}>
      <div className='mx-auto px-4 sm:px-6 lg:max-w-8xl lg:px-8'>
        <div className='mx-auto md:hidden'>
          <img src={dividerTopMobile} alt='Divider with Lighthouse logo' />
        </div>
        <div className='mx-auto hidden max-w-8xl md:block'>
          <img src={dividerTopDesktop} alt='Divider with Lighthouse logo' />
        </div>
        <div className='mx-auto mt-8 max-w-prose text-center text-lg text-brandText sm:mt-10 lg:text-xl'>
          <h2 className='balance mb-3 text-3xl font-extrabold tracking-tight text-brandText md:mb-6 md:text-4xl lg:text-5xl'>
            A Win For Both Teachers and Administrators
          </h2>
          <p className={'balance'}>
            Compass is not just for administrators, it’s also for teachers!
            Teachers can also benefit from more time and convenience, since they
            can log into one system to see everything.
          </p>
        </div>
        <div
          className={'mt-8 flex flex-col items-center justify-center sm:mt-10'}
        >
          <div className={'w-full max-w-4xl'}>
            <img
              src={image}
              alt=''
              className='w-full rounded-lg object-cover shadow-xl'
            />
          </div>
        </div>
      </div>
    </section>
  );
}
