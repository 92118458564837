import dataModel from '../../../images/blog/data_model.png';
import PostAuthor from './PostAuthor';
import authorImage from '../../../images/dave.webp';

export default function PostTwo() {
  return (
    <div className='relative mt-16 overflow-hidden bg-white px-2 sm:px-6 lg:mt-32 lg:px-8'>
      <div className='hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full'>
        <div
          className='relative mx-auto h-full max-w-prose text-lg'
          aria-hidden='true'
        >
          <svg
            className='absolute top-12 left-full translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='74b3fd99-0a6f-4271-bef2-e80eeafdf357'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)'
            />
          </svg>
          <svg
            className='absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='f210dbf6-a58d-4871-961e-36d5016a0f49'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#f210dbf6-a58d-4871-961e-36d5016a0f49)'
            />
          </svg>
          <svg
            className='absolute bottom-12 left-full translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='d3eb07ae-5182-43e6-857d-35c643af9034'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#d3eb07ae-5182-43e6-857d-35c643af9034)'
            />
          </svg>
        </div>
      </div>
      <div className='relative px-4 sm:px-6 lg:px-8'>
        <div className='mx-auto max-w-prose text-lg'>
          <h1 className='text-center text-3xl font-bold tracking-tight text-brandText lg:text-4xl xl:text-5xl'>
            Data <span className='text-brandPrimary'>DRIVEN</span> Schools Part
            1
          </h1>
          <p className='mt-8 text-xl leading-8 text-brandText'>
            The phrase data driven gets thrown around in education so often it
            can start to sound empty and hollow. What does that phrase really
            mean? To say an organization is data driven is really just making a
            statement about the larger Culture of Analytics (COA). Every org has
            a COA, it’s just a matter of what statements can be made about it.
          </p>
        </div>
        <div className='prose prose-lg prose-indigo mx-auto mt-6 text-brandText'>
          <h2 className='text-brandText'>Defining Culture of Analytics</h2>
          <p>
            In short, a culture of analytics is a function of the data
            available, people’s capacity for understanding and acting on data,
            and the processes and policies of the organization around data. (ie,
            if the data says to stop doing something, do we act on it?)
          </p>
          <p>
            The next several posts will address all three parts. We start with
            the data, because it is the core of the process, and trying to build
            anything on bad data usually goes bad pretty fast. But there are a
            few basic steps you can follow to lay the foundation to transform
            your district or school.
          </p>
          <h2 className='text-brandText'>Step 1: Leadership Investment</h2>
          <p>
            Separate from technology, the top management needs to both devote
            time to and to understand the organization’s data architecture and
            strategy. It’s not necessary for the leadership team to be aware of
            the gritty details, but they should be aware of the key processes
            and strategies. Have regular meetings with your top data staff
            members. Make sure they are supported.
          </p>
          <h2 className='text-brandText'>
            Step 2: Let People Select Their Tools
          </h2>
          <p>
            It's very unlikely that all the data needs can be met by one
            software or platform. As a matter of practicality, your staff will
            be far more productive and happy using the tools they select–it’s
            what helps they do their best work. This is much preferred over
            staff using inefficient tools and methods in order to conform to
            analytical processes.
          </p>
          <h2 className='text-brandText'>Step 3: Determine KPIs</h2>
          <p>
            The leadership team needs to make decisions about what metrics will
            be the focus, how they should be measured, and eventually benchmarks
            or targets for achievement. For example, the team may want to focus
            on grades, so they could look at some combination of things like GPA
            (and overall measure) and/or failures (a specific grade).
          </p>
          <h2 className='text-brandText'>Step 4: Identify Truth Sources</h2>
          <p>
            The sources of mission critical data should be identified and
            documented. Similarly, Key Performance Indicators should be
            developed, so there are shared ways of looking at data and
            evaluating it. Through discussing district goals and circumstances,
            stakeholders can agree upon ways of measuring and interpreting the
            data.
          </p>
          <h2 className='text-brandText'>Step 4: Identify Truth Sources</h2>
          <p>
            While it’s certainly possible to work directly with live data from
            an operational system, there’s greater efficiency if you create a
            data warehouse, a central repository for all the key data. These can
            vary in complexity, but even simple implementations can be
            enormously impactful by providing trusted, prepared sources of truth
            and self-service access for greater agility. The team can act on
            data instead of preparing and analyzing it.
          </p>
          <figure>
            <img
              className='w-full rounded-lg'
              src={dataModel}
              alt=''
              width={1310}
              height={873}
            />
            <figcaption>Sample High School Data Model</figcaption>
          </figure>
          <h2 className='text-brandText'>Step 6: Governance</h2>
          <p>
            It is well worth the time commitment of the leadership team to
            understand how data governance works at a high level in the
            organization. Most importantly, it puts team members on the same
            page with regard to key information, its meaning, and how to act on
            it. Without governance, the best systems will degrade over time.
          </p>
          <p>
            Having a central data strategy and system for storing and analyzing
            critical information can seem like a daunting, laborious task. While
            it’s true that some parts of this can get a little tedious, the
            resulting product is a massive time saver. Do a quick search on data
            prep, and you’ll find one article after another reaffirming that
            preparing the data is 80%. If that’s true, having a solid data
            management plan with governance allows you to drastically increase
            the amount of time people spend analyzing and acting on data rather
            than preparing it. It allows your staff to work confidently and
            efficiently within a common framework of trusted, reliable data,
            measurements, and interpretation.
          </p>
          <PostAuthor
            image={authorImage}
            name={'David Kush'}
            title={'Managing Director'}
          />
        </div>
      </div>
    </div>
  );
}
