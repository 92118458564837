import dividerBottomDesktop from '../../../../images/dividers/compass_navigate_desktop.svg';
import dividerBottomMobile from '../../../../images/dividers/compass_navigate_mobile.svg';
import ReactPlayer from 'react-player';

export default function SectionFour() {
  return (
    <section className={'mx-auto bg-gray-100 py-20 lg:py-32'}>
      <div className='mx-auto px-4 sm:px-6 lg:max-w-8xl lg:px-8'>
        <div className='mx-auto md:hidden'>
          <img src={dividerBottomMobile} alt='Divider with Lighthouse logo' />
        </div>
        <div className='mx-auto hidden max-w-8xl md:block'>
          <img src={dividerBottomDesktop} alt='Divider with Lighthouse logo' />
        </div>
        <div className='mx-auto mt-8 max-w-prose text-center text-lg text-brandText sm:mt-10 lg:text-xl'>
          <h2 className='balance mb-3 text-3xl font-extrabold tracking-tight text-brandText md:mb-6 md:text-4xl lg:text-5xl'>
            Tools That Are Easy to Navigate
          </h2>
        </div>
        <div
          className={'mt-8 flex flex-col items-center justify-center sm:mt-10'}
        >
          <div className={'aspect-video w-full max-w-4xl ring ring-slate-200'}>
            <ReactPlayer
              width={'100%'}
              height={'100%'}
              url='https://youtu.be/T101BPEAriU'
              controls={true}
              playing={true}
              loop={true}
              muted={true}
            />
          </div>
        </div>
        <div className='mx-auto mt-8 max-w-prose text-center text-lg text-brandText sm:mt-10 lg:text-xl'>
          <p className={'balance'}>
            Shown above is a sample of our easy to navigate Administrator's
            Dashboard.
          </p>
        </div>
      </div>
    </section>
  );
}
