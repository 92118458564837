import PostAuthor from './PostAuthor';
import authorImage from '../../../images/dave.webp';

export default function PostSeven() {
  return (
    <div className='relative mt-16 overflow-hidden bg-white px-2 sm:px-6 lg:mt-32 lg:px-8'>
      <div className='hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full'>
        <div
          className='relative mx-auto h-full max-w-prose text-lg'
          aria-hidden='true'
        >
          <svg
            className='absolute top-12 left-full translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='74b3fd99-0a6f-4271-bef2-e80eeafdf357'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)'
            />
          </svg>
          <svg
            className='absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='f210dbf6-a58d-4871-961e-36d5016a0f49'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#f210dbf6-a58d-4871-961e-36d5016a0f49)'
            />
          </svg>
          <svg
            className='absolute bottom-12 left-full translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='d3eb07ae-5182-43e6-857d-35c643af9034'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#d3eb07ae-5182-43e6-857d-35c643af9034)'
            />
          </svg>
        </div>
      </div>
      <div className='relative px-4 sm:px-6 lg:px-8'>
        <div className='mx-auto max-w-prose text-lg'>
          <h1 className='text-center text-3xl font-bold tracking-tight text-brandText lg:text-4xl xl:text-5xl'>
            Gap <span className='text-brandPrimary'>Thinking</span>
          </h1>
          <p className='mt-8 text-xl leading-8 text-brandText'>
            One way to help improve teaching and learning is to focus on Gap
            Thinking. When students are not providing us with work at our
            expectation level, it is considered a deficiency or “gap” in what is
            desired vs. what is the student’s current mastery. Different from
            the deficiency, Gap Thinking is not what they don’t know, but rather
            the incorrect thinking that leads to the wrong answer. With just
            about anything people learn, improvement comes from correcting
            faulty logic, assumptions, and habits, not from merely pointing out
            what is deficient.
          </p>
        </div>
        <div className='prose prose-lg prose-indigo mx-auto mt-6 text-brandText'>
          <p>
            We can help our students with more precise feedback and instruction
            when we uncover what they actually think about a topic, and work
            with them to correct problems or access the appropriate practice
            needed to reach mastery. You can gather information about gap
            thinking while you are assessing a student. This is done primarily
            by getting the student to reflect during the assessment. It can be
            as simple as questions that require description of their process or
            pointed questions about specific steps.
          </p>
          <p>
            For example, if a student is repeatedly getting a math problem
            wrong, among the causes could be knowing how to multiply negatives,
            that the student doesn’t understand the steps or process, or may be
            unable to deal with application, as often found by word problems.
            Regardless, structuring a math assessment to provide clues into
            which of these may be the issue is an effective way to help the
            student grow. A good personal trainer wouldn’t merely inform
            somebody they’re overweight or that they eat too much. They might
            help a person realize, for example, that it’s not as simple as how
            many carbohydrates you eat, but also what types and when.
          </p>
          <p>
            This really isn’t anything new, is it? For years, math teachers have
            asked for students to show their work and marked the type of error.
            The additional value of focusing on gap thinking is getting the
            students to focus on it as well. If the assessments and criteria are
            structured with this end in mind, we can increase the students’
            ability to diagnose and correct their own gap thinking, or at least
            have a greater sense of ownership and control. It’s not hard to see
            how it can be easier and more effective to help a student when they
            approach you with a better sense of their own deficiencies. Granted,
            they will need help with this, but the mentality of “why am I wrong
            and how do I fix it” is much more conducive to education than “what
            did I get wrong.” If we don’t ask for more, they often stop at
            judgment, rather than reflect.
          </p>
          <PostAuthor
            image={authorImage}
            name={'David Kush'}
            title={'Managing Director'}
          />
        </div>
      </div>
    </div>
  );
}
