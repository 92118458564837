import ServiceCard from '../../ServiceCard';
import integralCard from '../../../images/service_cards/integral_card.png';
import insightCard from '../../../images/service_cards/insight_card.png';
import lighthouseCard from '../../../images/service_cards/lighthouse_card.png';
import compassCard from '../../../images/service_cards/compass_card.png';
import adviserCard from '../../../images/service_cards/adviser_card.png';
import adaptCard from '../../../images/service_cards/adapt_card.png';
import dataLightIcon from '../../../images/icons/data_light.svg';
import studentLightIcon from '../../../images/icons/student_light.svg';
import mtssLightIcon from '../../../images/icons/mtss_light.svg';
import adminLightIcon from '../../../images/icons/admin_light.svg';
import consultingLightIcon from '../../../images/icons/consulting_light.svg';
import customLightIcon from '../../../images/icons/custom_light.svg';

export default function Services() {
  return (
    <section className='mx-auto mt-16 max-w-8xl px-0 sm:px-6 lg:mt-32 lg:px-8'>
      <div className='mx-auto'>
        <div className='flex flex-col items-center px-4 sm:px-6 lg:px-8'>
          <h2 className='text-center text-3xl font-extrabold tracking-tight text-brandText md:text-4xl lg:text-5xl'>
            Master Educational Data with Polaris
          </h2>
          <p className='mt-3 max-w-prose text-center text-lg text-brandText lg:text-xl'>
            Discover how Polaris provides administrators and teachers products
            to accelerate learning with each student.
          </p>
        </div>
        <div className='mt-10 mb-10 grid grid-cols-1 sm:grid-cols-2 sm:gap-8 lg:grid-cols-3'>
          <ServiceCard
            cardImage={integralCard}
            href={'/integral'}
            cardName={'Integral'}
            cardIcon={dataLightIcon}
          />
          <ServiceCard
            cardImage={insightCard}
            href={'/insight'}
            cardName={'Insight'}
            cardIcon={studentLightIcon}
          />
          <ServiceCard
            cardImage={lighthouseCard}
            href={'/lighthouse'}
            cardName={'Lighthouse'}
            cardIcon={mtssLightIcon}
          />
          <ServiceCard
            cardImage={compassCard}
            href={'/compass'}
            cardName={'Compass'}
            cardIcon={adminLightIcon}
          />
          <ServiceCard
            cardImage={adviserCard}
            href={'/adviser'}
            cardName={'Adviser'}
            cardIcon={consultingLightIcon}
          />
          <ServiceCard
            cardImage={adaptCard}
            href={'/adapt'}
            cardName={'Adapt'}
            cardIcon={customLightIcon}
          />
        </div>
        <div className='px-4 sm:mx-6 lg:px-8'>
          <p className='mx-auto max-w-prose text-center text-lg text-brandText'>
            With your existing data and Lighthouse, Polaris’s MTSS Management
            System, you can help each and every student find their path!
          </p>
          <p className='mx-auto mt-8 max-w-prose text-center text-lg text-brandText'>
            Polaris streamlines your current data, keeping all of the
            information easily accessible in one place, so you can focus on
            helping students learn! Not being tied to any specific product or
            vendor allows Polaris to adapt to your existing data systems and
            processes! There is no other comparable MTSS management tool
            available!{' '}
          </p>
        </div>
      </div>
    </section>
  );
}
