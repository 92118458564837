import mtssTopImage from '../../../../images/service_pages/compass/mtss_top.webp';
import mtssBottomImage from '../../../../images/service_pages/compass/mtss_bottom.webp';

export default function SectionTwo() {
  return (
    <section className={'mx-auto mt-20 bg-gray-100 py-20 lg:mt-24 lg:py-32'}>
      <div className='mx-auto px-4 sm:px-6 lg:max-w-8xl lg:px-8'>
        <div className='mx-auto max-w-prose text-center text-lg text-brandText lg:text-xl'>
          <h2 className='balance mb-3 text-3xl font-extrabold tracking-tight text-brandText md:mb-6 md:text-4xl lg:text-5xl'>
            Getting the Information You Need Without Wasting time.
          </h2>
          <p className={'balance'}>
            Traditionally 80% of the time spent on analytics is just on getting
            the data together. Educators are inundated with data tasks, often
            centered around trying to get data from different sources to play
            nicely. Compass takes care of all that work for you, so while your
            staff uses the tools that are most comfortable for their different
            roles, the data automatically flows into easy-to-navigate,
            interactive dashboards. Instead of time being eaten up wrangling
            forms and spreadsheets, it can be devoted to working with students
            and supporting their needs.
          </p>
        </div>
        <div
          className={
            'mx-auto mt-8 flex max-w-4xl flex-col bg-gray-100 sm:mt-10'
          }
        >
          <div className='overflow-hidden rounded-xl shadow-md shadow-slate-300'>
            <img
              src={mtssTopImage}
              alt='MTSS Dashboard'
              className='w-full overflow-hidden rounded-xl'
            ></img>
          </div>
          <div className='mt-8 overflow-hidden rounded-xl shadow-md shadow-slate-300 ring-2 ring-slate-200 sm:mt-10'>
            <img src={mtssBottomImage} alt='MTSS Dashboard'></img>
          </div>
        </div>
      </div>
    </section>
  );
}
