export default function PageTopper({
  heading,
  headingSpan,
  paragraph,
  paragraphTwo,
  image,
  imageAlt,
}) {
  return (
    <section className='service-topper-container mx-auto -mt-4 max-w-8xl px-4 sm:px-6 lg:mt-12 lg:px-8'>
      <div className='hidden lg:flex'>
        <div className='flex w-[45%] flex-col justify-center bg-gray-100 px-12 pb-32 pt-48'>
          <h2 className='text-4xl font-extrabold tracking-tight text-brandText sm:text-5xl md:text-6xl lg:text-5xl lg:leading-[55px] xl:text-6xl xl:leading-[70px]'>
            {heading} <br />{' '}
            <span className='text-brandPrimary lg:leading-[55px] xl:leading-[70px]'>
              {headingSpan}
            </span>
          </h2>
          <p className='mx-auto mt-3 text-lg text-brandText sm:text-xl md:mt-5'>
            {paragraph}
          </p>
          {paragraphTwo && (
            <p className='mx-auto mt-8 text-lg text-brandText sm:text-xl'>
              {paragraphTwo}
            </p>
          )}
        </div>
        <div className='flex w-[55%] items-center justify-center bg-brandPrimary'>
          <div className='flex h-full max-w-2xl'>
            <img className='object-contain pt-20' src={image} alt={imageAlt} />
          </div>
        </div>
      </div>
      {/* Mobile Version */}
      <div className='mt-14 flex flex-col items-center md:mt-24 lg:hidden'>
        <div className='flex w-full flex-col items-center text-center'>
          <h1 className='text-4xl font-extrabold tracking-tight text-brandText sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl'>
            <span className='block leading-[45px] sm:leading-[55px] md:leading-[65px] xl:inline'>
              {heading}
            </span>{' '}
            <span className='block leading-[45px] text-brandPrimary sm:leading-[55px] md:leading-[65px] xl:inline'>
              {headingSpan}
            </span>
          </h1>
          <div className='mobile-service-topper-cutout mt-6 sm:mt-8'>
            <div className=' bg-brandPrimary'>
              <img
                className='pt-10 pb-3 sm:px-12 sm:pt-14 md:px-20 md:pt-20'
                src={image}
                alt={imageAlt}
              />
            </div>
          </div>
          <p className='mx-auto mt-8 max-w-md text-lg text-gray-500 sm:text-xl md:mt-10 md:max-w-3xl'>
            {paragraph}
          </p>
          {paragraphTwo && (
            <p className='mx-auto mt-8 max-w-md text-lg text-gray-500 sm:text-xl md:max-w-3xl'>
              {paragraphTwo}test
            </p>
          )}
        </div>
      </div>
    </section>
  );
}

PageTopper.defaultProps = {
  paragraphTwo: null,
};
