export default function PostAuthor({ image, name, title }) {
  return (
    <div className='flex items-center space-x-4 lg:space-x-6'>
      <img
        className='h-20 w-20 rounded-full sm:h-28 sm:w-28'
        src={image}
        alt='Dave Kush'
      />
      <div className='text-lg font-medium sm:text-xl'>
        <p className='my-0 pb-1'>{name}</p>
        <p className='my-0 pt-1 text-brandPrimary'>{title}</p>
      </div>
    </div>
  );
}
