import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import PageTopper from '../../components/sections/Services/PageTopper';
import image from '../../images/service_cards/insight_card.png';
import teacherImage from '../../images/service_pages/insight/teacher.webp';
import ImageLeftTextRight from '../../components/sections/Services/ImageLeftTextRight';
import PcInfographic from '../../components/sections/Services/Insight/PcInfographic';
import attendanceImage from '../../images/service_pages/insight/attendance.png';
import ImageRightTextLeft from '../../components/sections/Services/ImageRightTextLeft';
import behaviorImage from '../../images/service_pages/insight/behavior.png';
import gradesImage from '../../images/service_pages/insight/grades.png';
import StateScores from '../../components/sections/Services/Insight/StateScores';
import mathImage from '../../images/service_pages/insight/math.png';
import readingImage from '../../images/service_pages/insight/reading.png';
import actionsImage from '../../images/service_pages/insight/actions.png';
import noteImage from '../../images/service_pages/insight/note.webp';

export default function Insight() {
  return (
    <main>
      <NavBar />
      <PageTopper
        heading={'The Most Comprehensive'}
        headingSpan={'Student Report Available'}
        paragraph={`Our comprehensive student report provides you with the most complete, updated, actionable 
        information available! Grades, attendance, discipline, state assessments, and local educational tools are all 
        included, enabling individuals and collaborative teams to find the best path forward.`}
        image={image}
        imageAlt={'Insight Logo'}
      />
      <ImageLeftTextRight
        image={teacherImage}
        imageAlt={'Woman teacher speaking to child in classroom'}
        heading={`It's All in One Place`}
        paragraphOne={`Our detailed and comprehensive reports provide a quick glance into the most important areas of student development.`}
        paragraphTwo={`Educators can monitor trends in grades and learning standards, as well as who is already receiving extra help.`}
        paragraphOneClasses={'mb-10'}
        paragraphTwoClasses={''}
        containerClasses={'mx-auto mt-20 py-20 lg:py-32 lg:mt-24 bg-gray-100'}
        imageContainerClasses={'max-w-xl lg:max-w-none'}
        imageClasses={'rounded-3xl shadow-xl lg:min-w-[300px] max-h-[500px]'}
        flexContainerClasses={
          'flex flex-col items-center justify-center lg:space-x-10 lg:justify-evenly lg:flex-row'
        }
      />
      <PcInfographic />
      <ImageRightTextLeft
        image={attendanceImage}
        imageAlt={'Grades and charts example'}
        heading={'Attendance'}
        paragraphOne={`Instantly view a summary of the days your students are absent. This data is easily viewable from the dashboard.`}
        paragraphTwo={''}
        paragraphOneClasses={''}
        paragraphTwoClasses={'hidden'}
        containerClasses={'mx-auto mt-0 bg-white py-20 lg:py-32'}
        imageContainerClasses={'max-w-xl lg:max-w-none'}
        imageClasses={'rounded-3xl min-w-[400px] shadow-xl max-h-[525px]'}
        flexContainerClasses={
          'flex flex-col items-center lg:space-x-10 justify-evenly lg:flex-row'
        }
      />
      <ImageLeftTextRight
        image={behaviorImage}
        imageAlt={'Behavior data visualization example'}
        heading={'Behavior'}
        paragraphOne={`You will have instant access to any behavior reports that have been filed.  Provided will be 
        the incident date, location, description, category, and role.`}
        paragraphTwo={``}
        paragraphOneClasses={''}
        paragraphTwoClasses={''}
        containerClasses={'mx-auto py-20 lg:py-32 bg-gray-100'}
        imageContainerClasses={'max-w-xl lg:max-w-none'}
        imageClasses={'rounded-3xl min-w-[400px] max-h-[525px] shadow-xl'}
        flexContainerClasses={
          'flex flex-col items-center lg:space-x-10 justify-evenly lg:flex-row'
        }
      />
      <ImageRightTextLeft
        image={gradesImage}
        imageAlt={'Grades chart example'}
        heading={'Previous Grades'}
        paragraphOne={`View previous grades a student has earned by grade level, course, semester, trimester, or standard.`}
        paragraphTwo={''}
        paragraphOneClasses={''}
        paragraphTwoClasses={'hidden'}
        containerClasses={'mx-auto mt-0 bg-white py-20 lg:py-32'}
        imageContainerClasses={'max-w-2xl lg:max-w-none'}
        imageClasses={
          'rounded-3xl lg:min-w-[550px] xl:min-w-[725px]  shadow-xl max-h-[525px]'
        }
        flexContainerClasses={
          'flex flex-col items-center lg:space-x-10 justify-between lg:flex-row'
        }
      />
      <StateScores
        imageOne={readingImage}
        imageOneAlt={'State reading exam results'}
        imageTwo={mathImage}
        imageTwoAlt={'State math exam results'}
        heading={'State Assessment'}
        paragraph={`We collect and provide both math and reading assessment data from State Assessments. 
        These tests help to adequately identify achievement gaps of students and help educators, administrators, 
        and other school professionals make more confident decisions driven by actual data.
        `}
      />
      <ImageLeftTextRight
        image={actionsImage}
        imageAlt={'List of actions in progress'}
        heading={'Actions in Progress'}
        paragraphOne={`An action is when a Treatment is provided to a student.  Check on any actions that are 
        currently in progress for each student.  This provides the user with treatment information, concern...`}
        paragraphTwo={``}
        paragraphOneClasses={''}
        paragraphTwoClasses={''}
        containerClasses={'mx-auto py-20 lg:py-32 bg-white'}
        imageContainerClasses={'max-w-xl lg:max-w-none'}
        imageClasses={'rounded-3xl min-w-[400px] max-h-[525px] shadow-xl'}
        flexContainerClasses={
          'flex flex-col items-center lg:space-x-10 justify-evenly lg:flex-row'
        }
      />
      <ImageRightTextLeft
        image={noteImage}
        imageAlt={'Notes page example'}
        heading={'Teacher Notes'}
        paragraphOne={`Your Notes are visible to anybody who can view a student's report.`}
        paragraphTwo={`Notes are the heart of the collaboration between SST and Teachers. Notes are a quick way for 
        teachers to document information about extra help or support being provided for students. 
        Notes are designed to be fast--two questions: What is the issue? What have you tried?`}
        paragraphOneClasses={'mb-10'}
        paragraphTwoClasses={''}
        containerClasses={'mx-auto mt-0 bg-gray-100 py-20 lg:py-32'}
        imageContainerClasses={'max-w-2xl lg:max-w-none'}
        imageClasses={
          'rounded-3xl lg:min-w-[500px] xl:min-w-[650px]  shadow-xl max-h-[525px]'
        }
        flexContainerClasses={
          'flex flex-col items-center lg:space-x-10 justify-between lg:flex-row'
        }
      />
      <Footer />
    </main>
  );
}
