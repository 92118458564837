import infographic1 from '../../../images/funding_infographic_1.webp';
import infographic2 from '../../../images/funding_infographic_2.webp';
import infographic3 from '../../../images/funding_infographic_3.webp';

export default function InfographicOne() {
  return (
    <section className='mx-auto mt-16 bg-gray-100 pt-16 pb-6 lg:mt-32'>
      <div className='mx-auto'>
        <p className='mx-auto max-w-4xl px-4 text-center text-xl font-medium text-brandText sm:px-6 sm:text-3xl sm:font-normal'>
          Grant funding can be applied to many things, but where will it have
          the most impact? <br /> <br />
          <span className='text-brandPrimary'>
            We'd love an opportunity to discuss your school or district's unique
            circumstances to see if Polaris Education would be a good fit. Many
            schools find they are able to help meet personal and key district
            goals with the help of Polaris:
          </span>
        </p>
      </div>
      <div className='mx-auto mt-0 flex max-w-8xl flex-col items-center justify-between space-y-2 py-10 px-4 sm:mt-6 sm:px-6 lg:mt-12 lg:px-8'>
        <div className='flex flex-col items-center lg:flex-row lg:space-x-10'>
          <div className='mx-6 mt-10 mb-3 flex max-w-[450px] sm:mx-auto sm:mb-5 lg:mb-0 lg:mt-0'>
            <img className='' src={infographic1} alt='' />
          </div>
          <div>
            <p className='mx-auto text-center text-xl text-brandText sm:text-xl md:max-w-2xl lg:max-w-xl lg:text-left'>
              By removing a lot of the important, but tedious, work from your
              staff's plates, Polaris significantly improve's their ability to
              manage the MTSS program and help more students achieve success.
            </p>
          </div>
        </div>
        <div className='flex flex-col items-center lg:flex-row lg:space-x-10'>
          <div className='mx-6 mt-10 mb-3 flex max-w-[450px] sm:mx-auto sm:mb-5 lg:mt-0 lg:mb-0 '>
            <img className='' src={infographic2} alt='' />
          </div>
          <div>
            <p className='mx-auto text-center text-xl text-brandText sm:text-xl md:max-w-2xl lg:max-w-xl lg:text-left'>
              Polaris can be covered by federal and US Department of Education
              grants, such as Title I, ESSER, and others. This means schools can
              access the resources they need without necessarily increasing
              their budgets. Even if you already receive some grant funds, there
              may be additional available. We are happy to help you review.
            </p>
          </div>
        </div>
        <div className='flex flex-col items-center lg:flex-row lg:space-x-10'>
          <div className='mx-6 mt-10 mb-3 flex max-w-[450px] sm:mx-auto sm:mb-5 lg:mt-0 lg:mb-0 '>
            <img className='' src={infographic3} alt='' />
          </div>
          <div>
            <p className='mx-auto text-center text-xl text-brandText sm:text-xl md:max-w-2xl lg:max-w-xl lg:text-left'>
              Polaris is cost effective, because even though funding sources are
              available, getting the most out of every resource is still a
              critical part of helping more students. Polaris reduces failure
              and other outcomes that deplete district resources.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
