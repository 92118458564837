export default function PageTopperV2({
  heading,
  headingSpan,
  paragraph,
  paragraphTwo,
  image,
  imageAlt,
  pageName,
  pageSlogan,
}) {
  return (
    <section className='service-topper-container mx-auto -mt-4 max-w-8xl px-4 sm:px-6 lg:mt-12 lg:px-8'>
      <div className='hidden lg:flex'>
        <div className='flex w-[45%] flex-col justify-center bg-gray-100 px-12 pb-32 pt-48'>
          <h2 className='text-4xl font-extrabold tracking-tight text-brandText sm:text-5xl md:text-6xl lg:text-5xl lg:leading-[55px] xl:text-6xl xl:leading-[70px]'>
            {heading} <br />{' '}
            <span className='text-brandPrimary lg:leading-[55px] xl:leading-[70px]'>
              {headingSpan}
            </span>
          </h2>
          <p className='mx-auto mt-3 text-lg text-brandText sm:text-xl md:mt-5'>
            {paragraph}
          </p>
          {paragraphTwo && (
            <p className='mx-auto mt-8 text-lg text-brandText sm:text-xl'>
              {paragraphTwo}
            </p>
          )}
        </div>
        <div className='flex w-[55%] flex-col items-center justify-center bg-brandPrimary'>
          <p className='z-10 pt-20 text-5xl font-extrabold text-white'>
            {pageName}
          </p>
          <div className='flex w-72'>
            <img className='object-contain' src={image} alt={imageAlt} />
          </div>
          <p className='balance px-8 text-center text-5xl font-extrabold text-white'>
            {pageSlogan}
          </p>
        </div>
      </div>
      {/* Mobile Version */}
      <div className='mt-14 flex flex-col items-center md:mt-24 lg:hidden'>
        <div className='flex w-full flex-col items-center text-center'>
          <h1 className='text-4xl font-extrabold tracking-tight text-brandText sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl'>
            <span className='block leading-[45px] sm:leading-[55px] md:leading-[65px] xl:inline'>
              {heading}
            </span>{' '}
            <span className='block leading-[45px] text-brandPrimary sm:leading-[55px] md:leading-[65px] xl:inline'>
              {headingSpan}
            </span>
          </h1>
          <div className='mobile-service-topper-cutout mt-6 sm:mt-8'>
            <div className=' bg-brandPrimary pt-24 pb-10 sm:pb-16 sm:pt-32'>
              <p className='px-8 text-center text-3xl font-extrabold text-white sm:text-5xl md:text-6xl'>
                {pageName}
              </p>
              <img
                className='mx-auto w-56 sm:w-72'
                src={image}
                alt={imageAlt}
              />
              <p className='balance px-8 text-center text-3xl font-extrabold text-white sm:text-5xl md:text-6xl'>
                {pageSlogan}
              </p>
            </div>
          </div>
          <p className='mx-auto mt-8 max-w-md text-lg text-gray-500 sm:text-xl md:mt-10 md:max-w-3xl'>
            {paragraph}
          </p>
          {paragraphTwo && (
            <p className='mx-auto mt-8 max-w-md text-lg text-gray-500 sm:text-xl md:max-w-3xl'>
              {paragraphTwo}test
            </p>
          )}
        </div>
      </div>
    </section>
  );
}

PageTopperV2.defaultProps = {
  paragraphTwo: null,
};
