export default function Resources({ resourceList }) {
  return (
    <div className='relative mt-12 bg-gray-100 px-4 pb-6 sm:px-6 lg:px-8'>
      <div className='absolute inset-0'>
        <div className='h-1/3 bg-white sm:h-2/3' />
      </div>
      <div className='relative mx-auto max-w-8xl'>
        <div className='mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3'>
          {resourceList.map((resource) => (
            <div
              key={resource.title}
              className='flex flex-col overflow-hidden rounded-lg shadow-lg'
            >
              <a href={resource.href} target={'_blank'} rel='noreferrer'>
                <div className='flex-shrink-0'>
                  <img
                    className='h-48 w-full object-cover'
                    src={resource.imageUrl}
                    alt=''
                  />
                </div>
              </a>
              <div className='flex flex-1 flex-col justify-between bg-white p-6'>
                <div className='flex-1'>
                  <a href={resource.href} target={'_blank'} rel='noreferrer'>
                    <p className='mt-2 text-2xl font-semibold text-brandText'>
                      {resource.title}
                    </p>
                  </a>
                  <div>
                    <p className='text-base font-semibold text-brandText opacity-75'>
                      {resource.source}
                    </p>

                    <p className='mt-3 mb-6 text-brandText'>
                      {resource.summary}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
