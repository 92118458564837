import Footer from '../../components/Footer';
import GrayCTA from '../../components/GrayCTA';
import NavBar from '../../components/NavBar';
import PostNine from '../../components/sections/BlogPost/PostNine';

export default function BlogPostNine() {
  return (
    <main>
      <NavBar />
      <PostNine />
      <GrayCTA margins={'mt-16 lg:mt-32'} />
      <Footer />
    </main>
  );
}
