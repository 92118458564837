import divider from '../../../images/dividers/funding_divider.png';
import DividerWithHeading from '../../../components/DividerWithHeading';
import { Link } from 'react-router-dom';

export default function InfographicTwo() {
  return (
    <section className='mx-auto my-16 max-w-8xl px-4 sm:px-6 lg:my-32 lg:px-8'>
      <DividerWithHeading
        image={divider}
        heading={'Try a Risk Free Consultation'}
        paragraph={
          "If our products don't seem like a fit for your school or district, we won't pressure. Our goal is to listen to you and share what we've learned from other clients. If we can be of help, great! If not, we won't take anymore of your time."
        }
      />
      <div className='mx-auto mt-20 text-center'>
        <Link to={'/contact'}>
          <div className='mx-auto flex max-w-max rounded-md bg-brandPrimary p-5 text-xl font-bold text-white sm:text-2xl'>
            Request a Personalized <br className='sm:hidden' /> Funding
            Consultation
          </div>
        </Link>
      </div>
    </section>
  );
}
