import { Link } from 'react-router-dom';

import {
  AcademicCapIcon,
  BadgeCheckIcon,
  CashIcon,
  ClockIcon,
  ReceiptRefundIcon,
  UsersIcon,
} from '@heroicons/react/outline';

const resources = [
  {
    title: 'MTSS',
    // summary:
    //   'A brief summary about the category. This information should describe what type of information the user might find in this category.',
    href: '/resources/mtss-resources',
    numberOfResources: '2',
    icon: ClockIcon,
    iconForeground: 'text-brandPrimaryLight',
    iconBackground: 'bg-brandPrimary',
    imageUrl:
      'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
  },
  {
    title: 'Standards-Based Instruction/Teaching',
    // summary:
    //   'A brief summary about the category. This information should describe what type of information the user might find in this category.',
    href: '/resources/standards-based-instruction-teaching-resources',
    numberOfResources: '4',
    icon: BadgeCheckIcon,
    iconForeground: 'text-brandPrimaryLight',
    iconBackground: 'bg-brandPrimary',
    imageUrl:
      'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
  },
  {
    title: 'Toolkits',
    // summary:
    //   'A brief summary about the category. This information should describe what type of information the user might find in this category.',
    href: '/resources/toolkit-resources',
    numberOfResources: '2',
    icon: UsersIcon,
    iconForeground: 'text-brandPrimaryLight',
    iconBackground: 'bg-brandPrimary',
    imageUrl:
      'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
  },
  {
    title: 'Use of Data',
    // summary:
    //   'A brief summary about the category. This information should describe what type of information the user might find in this category.',
    href: '/resources/use-of-data-resources',
    numberOfResources: '5',
    icon: CashIcon,
    iconForeground: 'text-brandPrimaryLight',
    iconBackground: 'bg-brandPrimary',
    imageUrl:
      'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
  },
  {
    title: 'Track Success of Programming',
    // summary:
    //   'A brief summary about the category. This information should describe what type of information the user might find in this category.',
    href: '/resources/tracking-success-of-programming-resources',
    numberOfResources: '3',
    icon: ReceiptRefundIcon,
    iconForeground: 'text-brandPrimaryLight',
    iconBackground: 'bg-brandPrimary',
    imageUrl:
      'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
  },
  {
    title: 'Equity and Data',
    // summary:
    //   'A brief summary about the category. This information should describe what type of information the user might find in this category.',
    href: '/resources/equity-and-data-resources',
    numberOfResources: '3',
    icon: AcademicCapIcon,
    iconForeground: 'text-brandPrimaryLight',
    iconBackground: 'bg-brandPrimary',
    imageUrl:
      'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
  },
  {
    title: 'Communicating Data and Goals with Staff',
    // summary:
    //   'A brief summary about the category. This information should describe what type of information the user might find in this category.',
    href: '/resources/communicating-data-and-goals-with-staff-resources',
    numberOfResources: '3',
    icon: AcademicCapIcon,
    iconForeground: 'text-brandPrimaryLight',
    iconBackground: 'bg-brandPrimary',
    imageUrl:
      'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ResourceList() {
  return (
    <section className='mx-auto my-16 px-4 sm:mx-6 lg:px-8'>
      <ul className='mx-auto grid max-w-8xl grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
        {resources.map((resource) => (
          <li
            key={resource.title}
            className='col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg border-2 border-gray-200 bg-white text-center'
          >
            <div className='flex flex-1 flex-col items-center p-8'>
              {/* <img
                className='mx-auto h-32 w-32 flex-shrink-0 rounded-2xl'
                src={resource.imageUrl}
                alt=''
              /> */}
              <Link to={resource.href}>
                <div>
                  <span
                    className={classNames(
                      resource.iconBackground,
                      resource.iconForeground,
                      'inline-flex rounded-lg p-3 duration-300 ease-in-out hover:scale-110',
                    )}
                  >
                    <resource.icon className='h-32 w-56' aria-hidden='true' />
                  </span>
                </div>
              </Link>
              <h3 className='mt-6 text-xl font-semibold text-brandText'>
                {resource.title}
              </h3>
              <dl className='mt-1 flex flex-grow flex-col justify-between'>
                <dt className='sr-only'>Summary</dt>
                <dd className='text-sm text-brandText'>{resource.summary}</dd>
                <dt className='sr-only'>Number of Resources</dt>
                <dd className='mt-3'>
                  <span className='rounded-full bg-brandPrimaryLight bg-opacity-20 px-2 py-1 text-xs font-medium text-brandPrimary'>
                    {resource.numberOfResources} Resources
                  </span>
                </dd>
              </dl>
            </div>
            <div>
              <div className='-mt-px flex divide-x divide-gray-200'>
                <div className='flex w-0 flex-1'>
                  <Link
                    to={resource.href}
                    className='relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-brandText duration-300 ease-in-out hover:scale-110'
                  >
                    View Resources
                  </Link>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
}
