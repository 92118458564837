import BlueCTA from '../components/BlueCTA';
import Footer from '../components/Footer';
import GrayCTA from '../components/GrayCTA';
import NavBar from '../components/NavBar';
import Hero from '../components/sections/Home/Hero';
import Infographic from '../components/sections/Home/Infographic';
import InformationalVideo from '../components/sections/Home/InformationalVideo';
import Services from '../components/sections/Home/Services';
import Testimonials from '../components/sections/Home/Testimonials';

export default function Home() {
  return (
    <div>
      <NavBar />
      <main>
        <Hero />
        <Services />
        <GrayCTA margins={'my-16 lg:my-32'} />
        <Infographic />
        <InformationalVideo />
        <Testimonials />
        <BlueCTA margins={'mt-16 lg:mt-32'} />
      </main>
      <Footer />
    </div>
  );
}
