import postImage from '../../../images/blog/post_three.webp';
import PostAuthor from './PostAuthor';
import authorImage from '../../../images/dave.webp';

export default function PostThree() {
  return (
    <div className='relative mt-16 overflow-hidden bg-white px-2 sm:px-6 lg:mt-32 lg:px-8'>
      <div className='hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full'>
        <div
          className='relative mx-auto h-full max-w-prose text-lg'
          aria-hidden='true'
        >
          <svg
            className='absolute top-12 left-full translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='74b3fd99-0a6f-4271-bef2-e80eeafdf357'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)'
            />
          </svg>
          <svg
            className='absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='f210dbf6-a58d-4871-961e-36d5016a0f49'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#f210dbf6-a58d-4871-961e-36d5016a0f49)'
            />
          </svg>
          <svg
            className='absolute bottom-12 left-full translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='d3eb07ae-5182-43e6-857d-35c643af9034'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#d3eb07ae-5182-43e6-857d-35c643af9034)'
            />
          </svg>
        </div>
      </div>
      <div className='relative px-4 sm:px-6 lg:px-8'>
        <div className='mx-auto max-w-prose text-lg'>
          <h1 className='text-center text-3xl font-bold tracking-tight text-brandText lg:text-4xl xl:text-5xl'>
            Data <span className='text-brandPrimary'>DRIVEN</span> Schools Part
            2
          </h1>
          <p className='mt-8 text-xl leading-8 text-brandText'>
            In part one we addressed the importance of a strong data foundation
            to level-up your culture of analytics. Put simply, the data has to
            be accurate and available. That said, unless your staff knows what
            to do with the data, it's useless. Making sure people know how to
            marshall data for action is process that takes time to do right.
            With that said, here some steps you can to improve your staff's
            capacity with data.
          </p>
        </div>
        <div className='prose prose-lg prose-indigo mx-auto mt-6 text-brandText'>
          <h2 className='text-brandText'>
            Step 1: Acknowledge That Teachers Already Look at Data… Like, all
            the Time
          </h2>
          <p>
            That's what teachers do. And frankly, much of the data they see and
            act on (raised hands, questions in class, students mood/facial
            expressions, and much more) is never captured by the operational
            data systems, and when it is, you're just telling the teacher what
            they already know. Providing feedback in response to data gathered
            is a core function of teaching and learning. If you believe you have
            effective teachers, then believe they are using data. Imagine what
            goes through a teacher's mind when they've been calling parents,
            trying squeeze assignments out of reluctant learners, pulling small
            groups for focused instruction, but then the administrators show up
            and say, “We need to start looking at data.” It's easy to see why
            people become frustrated.
          </p>
          <h2 className='text-brandText'>
            Step 2: Encourage an Immediate Feedback Model
          </h2>
          <p>
            Let your teachers select/use their own tools to collect/document
            evidence of learning. This allows them to focus on more thoughtful
            use of data for instruction. The more data they can gather and
            provide feedback on, the more their students can learn, but that's
            with the caveat that the data they collect is authentic to learning.
            Feedback feeds learning.
          </p>
          <h2 className='text-brandText'>Step 3: Know the Limits of Data</h2>
          <p>
            It has become self-evident that data is another case of a new
            development failing to live up to the claim that “This will
            revolutionize education.” With that well-intentioned belief firmly
            planted in our minds, educators have spent much of the last 20 years
            turning the schools into a giant ant farms, but in many cases the
            only real output is finer definition of problems. While this is of
            some value, it's important to understand that moving various metrics
            and KPIs can be a stubborn endeavor.
          </p>
          <figure>
            <img
              className='w-full rounded-lg'
              src={postImage}
              alt=''
              width={1310}
              height={873}
            />
            <figcaption>
              Taking time to build thoughtful data practices will have a big
              long-term impact.
            </figcaption>
          </figure>
          <h2 className='text-brandText'>
            Step 4: Engage Staff in Authentic Teacher Work
          </h2>
          <p>
            Trust us, teachers WANT to talk about teaching and learning. Discuss
            with them how they know whether or not kids are learning. What do
            they feel is the best evidence that learning has occurred? Whatever
            their answers are, this is the data to focus on. It's what matters
            to the staff, and what you'll be able to build buy-in around. This
            prevents a data culture from developing into meaningless forms and
            metrics that don't align with learning.
          </p>
          <h2 className='text-brandText'>
            Step 5: Establish Trust and Openness
          </h2>
          <p>
            Face it: people are afraid they will be fired, harassed, berated, or
            embarrassed with data, and frankly some of the most competent
            educators we've met were very much of that mindset. They were
            completely open to using data for themselves and their students, but
            worried about how it would be interpreted by others. The above items
            will go along way, as will being completely transparent and real
            with your staff about your goals and objectives. Proceed with
            sharing in baby steps, so people get comfortable seeing each others
            data and having their reviewed by their colleagues.
          </p>
          <p>
            The ideas above are not comprehensive, but they provide a starting
            point from which to build. In part 3 we will address some of the
            organizational behaviors and expectations that continue to multiply
            the value of your staff and data, so they can deliver the most
            value.
          </p>
          <PostAuthor
            image={authorImage}
            name={'David Kush'}
            title={'Managing Director'}
          />
        </div>
      </div>
    </div>
  );
}
