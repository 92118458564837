export default function PageTopper({
  imageUrl,
  mobileImage,
  pageTitle,
  heading,
  headingSpan,
  paragraph,
}) {
  return (
    <section className='page-topper-container mx-auto -mt-4 max-w-8xl px-4 sm:px-6 lg:mt-12 lg:px-8'>
      <div className='hidden lg:block'>
        <div>
          <div className='page-topper-clip-one flex h-60 w-full bg-brandTopperDark'></div>
          <div className='page-topper-clip-two -mt-60 flex h-60 w-full bg-brandTopperLight'></div>
          <div className='page-topper-clip-three -mt-60 flex h-60 w-full bg-brandPrimary'></div>
          <div className='page-topper-clip-three -mt-60 flex h-60 w-full items-center justify-center'>
            <h1 className='pb-16 text-center text-5xl font-bold text-white'>
              {pageTitle}
            </h1>
          </div>
        </div>
        <div className='flex h-24'></div>
        <div
          className={`-mt-52 flex justify-end ${imageUrl} bg-cover bg-center`}
        >
          <div className='page-topper-gradient-container w-1/2 pb-32 pt-[180px]'>
            <div className='ml-28 max-w-lg  pr-5 xl:ml-40'>
              <h2 className='mt-10 text-4xl font-extrabold tracking-tight text-brandText sm:text-5xl md:text-6xl lg:text-5xl lg:leading-[55px] xl:text-6xl xl:leading-[70px]'>
                {heading} <br />{' '}
                <span className='text-brandPrimary lg:leading-[55px] xl:leading-[70px]'>
                  {headingSpan}
                </span>
              </h2>
              <p className='mx-auto mt-3 max-w-md text-lg text-brandText sm:text-xl md:mt-5 md:max-w-3xl'>
                {paragraph}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile Version */}
      <div className='mt-10 flex flex-col items-center md:mt-20 lg:hidden'>
        <div className='flex w-full flex-col items-center text-center'>
          <div className='page-topper-clip-one flex h-24 w-full bg-brandTopperDark md:h-32'></div>
          <div className='page-topper-clip-three -mt-24 flex h-24 w-full bg-brandPrimary md:-mt-32 md:h-32'></div>
          <div className='page-topper-clip-three -mt-24 flex h-24 w-full items-center justify-center md:-mt-32 md:h-32'>
            <h2 className='pb-5 text-center text-3xl font-bold text-white sm:text-4xl md:text-5xl'>
              {pageTitle}
            </h2>
          </div>
          <h1 className='py-6 text-4xl font-extrabold leading-[45px] tracking-tight text-brandText sm:text-5xl sm:leading-[55px] md:py-12 md:text-6xl md:leading-[65px] lg:text-5xl xl:text-6xl'>
            <span className='block leading-[45px] sm:leading-[55px] md:leading-[65px] xl:inline'>
              {heading}
            </span>{' '}
            <span className='block leading-[45px] text-brandPrimary sm:leading-[55px] md:leading-[65px] xl:inline'>
              {headingSpan}
            </span>
          </h1>
          <div className='mt-5'>
            <img className='' src={mobileImage} alt='' />
          </div>
          <p className='mx-auto mt-8 max-w-md text-lg text-gray-500 sm:text-xl md:mt-10 md:max-w-3xl'>
            {paragraph}
          </p>
        </div>
      </div>
    </section>
  );
}
