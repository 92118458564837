import PostAuthor from './PostAuthor';
import authorImage from '../../../images/dave.webp';
import postImage from '../../../images/blog/blog_post_five.jpg';

export default function PostOne() {
  return (
    <div className='relative mt-16 overflow-hidden bg-white px-2 sm:px-6 lg:mt-32 lg:px-8'>
      <div className='hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full'>
        <div
          className='relative mx-auto h-full max-w-prose text-lg'
          aria-hidden='true'
        >
          <svg
            className='absolute top-12 left-full translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='74b3fd99-0a6f-4271-bef2-e80eeafdf357'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)'
            />
          </svg>
          <svg
            className='absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='f210dbf6-a58d-4871-961e-36d5016a0f49'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#f210dbf6-a58d-4871-961e-36d5016a0f49)'
            />
          </svg>
          <svg
            className='absolute bottom-12 left-full translate-x-32 transform'
            width={404}
            height={384}
            fill='none'
            viewBox='0 0 404 384'
          >
            <defs>
              <pattern
                id='d3eb07ae-5182-43e6-857d-35c643af9034'
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits='userSpaceOnUse'
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className='text-gray-200'
                  fill='currentColor'
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill='url(#d3eb07ae-5182-43e6-857d-35c643af9034)'
            />
          </svg>
        </div>
      </div>
      <div className='relative px-4 sm:px-6 lg:px-8'>
        <div className='mx-auto max-w-prose text-lg'>
          <h1 className='text-center text-3xl font-bold tracking-tight text-brandText lg:text-4xl xl:text-5xl'>
            Real-World <span className='text-brandPrimary'>Tasks</span> in{' '}
            <span className='text-brandPrimary'>Assessments</span>
          </h1>
          <p className='mt-8 text-xl leading-8 text-brandText'>
            An often repeated phrase by teachers in classrooms across America
            is, “they won’t take that in the real world!” It’s advice that, as
            educators, we need to take as well. After all, the purpose of school
            isn’t test scores, grades, and/or standards for their own sake. It’s
            to prepare students for the real world they are going to encounter.
            So while we work diligently to prepare students’ behaviors for life
            (I find this phrase is most frequently used with regard to behavior,
            not academics) we need to make sure we also structure learning that
            way.
          </p>
          <p className='mt-8 text-xl leading-8 text-brandText'>
            Assessments are a crucial aspect of any educational system. They
            serve as a means of measuring student progress, evaluating the
            effectiveness of teaching methods, and identifying areas where
            students need additional support. However, assessments can only be
            effective if they are linked to real-world tasks. Although
            intermediary data is helpful in diagnosing and intervening,
            ultimately the final outcomes we expect of students in school should
            be linked to things they will actually do in the real world. Below,
            we will discuss why it is essential for assessments in schools to be
            linked to real-world tasks, and how this can benefit both students
            and educators.
          </p>
        </div>
        <div className='prose prose-lg prose-indigo mx-auto mt-6 text-brandText'>
          <figure>
            <img
              className='w-full rounded-lg'
              src={postImage}
              alt=''
              width={1310}
              height={873}
            />
            {/* <figcaption>
              Taking time to build thoughtful data practices will have a big
              long-term impact.
            </figcaption> */}
          </figure>
          <h2 className='text-brandText'>What are Real-World Tasks?</h2>
          <p>
            Real-world tasks are tasks that are related to activities that
            individuals might encounter in their everyday lives. These tasks can
            be anything from solving a problem that requires critical thinking
            skills, to completing a project that requires collaboration and
            communication with others. Real-world tasks are valuable because
            they require students to apply the knowledge and skills they have
            learned in a meaningful context. This is important because it helps
            students to understand the relevance of what they are learning and
            to develop the skills that will be useful in their future careers
            and personal lives.
          </p>
          <h2 className='text-brandText'>
            Why are Real-World Tasks Important for Assessments in Schools?
          </h2>
          <p>
            Assessments that are linked to real-world tasks are important for
            several reasons. First, they provide a more accurate representation
            of a student's abilities. Traditional assessments, such as
            multiple-choice tests, often only measure a student's ability to
            recall information. Real-world tasks, on the other hand, require
            students to use their knowledge and skills in a practical way. This
            means that assessments that are linked to real-world tasks can
            provide a more comprehensive picture of a student's abilities.
          </p>
          <p>
            Second, assessments that are linked to real-world tasks can help
            students to see the relevance of what they are learning. When
            students understand the practical applications of what they are
            learning, they are more likely to be engaged and motivated to learn.
            This can lead to better academic outcomes and a greater sense of
            achievement.
          </p>
          <p>
            Third, assessments that are linked to real-world tasks can help
            teachers to identify areas where students need additional support.
            When students struggle with real-world tasks, it can indicate that
            they are lacking in certain skills or knowledge. This can help
            teachers to tailor their instruction to meet the specific needs of
            each student, which can lead to better academic outcomes.
          </p>
          <p>
            Fourth, assessments that are linked to real-world tasks can help to
            prepare students for their future careers. Many careers require
            individuals to be able to apply their knowledge and skills in
            practical settings. Assessments that are linked to real-world tasks
            can help to develop these skills in students, which can make them
            more competitive in the job market.
          </p>
          <h2 className='text-brandText'>Examples of Real-World Tasks</h2>
          <p>
            Real-world tasks can take many different forms. Here are some
            examples of real-world tasks that could be used as assessments in
            schools:
          </p>
          <ul>
            <li>
              Simulate the real thing: A colleague of mine once came to class
              with an obscure local election. He assigned students to use their
              phones to identify the candidates, key issues, each person’s
              stance on the issues, and ultimately who they would vote for. At
              the time, allowing students to use their phones was still slightly
              subversive, so the students ate it up even more!
            </li>
            <li>
              Designing a product: Students could be asked to design a product
              that solves a particular problem. This task would require students
              to use their critical thinking and problem-solving skills, as well
              as their creativity.
            </li>
            <li>
              Conducting an experiment: Students could be asked to design and
              conduct an experiment to test a hypothesis. This task would
              require students to use their scientific knowledge and skills to
              design and carry out a valid experiment.
            </li>
            <li>
              Creating a presentation: Students could be asked to create a
              presentation on a topic of their choice. This task would require
              students to use their communication skills and creativity to
              effectively convey information to others.
            </li>
            <li>
              Participating in a debate: Students could be asked to participate
              in a debate on a controversial topic. This task would require
              students to use their critical thinking, communication, and
              persuasion skills to make a convincing argument.
            </li>
          </ul>
          <p>
            Setting targets for students that are relevant and applicable to the
            lives they currently or will lead helps students in many ways,
            beyond the obvious actual value those skills provide. In the sense
            that they show us students real functional abilities, they are a
            more equitable means of evaluating students. Maybe most important,
            goals grounded in things that matter will matter a lot more to
            students than things they perceive as perfunctory or busywork.
          </p>
          <PostAuthor
            image={authorImage}
            name={'David Kush'}
            title={'Managing Director'}
          />
        </div>
      </div>
    </div>
  );
}
