import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import PageTopperV2 from '../../components/sections/Services/PageTopperV2';
import image from '../../images/service_cards/compass_card_v2.webp';
import SectionTwo from '../../components/sections/Services/Compass/SectionTwo';
import SectionThree from '../../components/sections/Services/Compass/SectionThree';
import SectionFour from '../../components/sections/Services/Compass/SectionsFour';

export default function Lighthouse() {
  return (
    <main>
      <NavBar />
      <PageTopperV2
        heading={'Actionable Data and Dashboards'}
        headingSpan={'That let you ACT!'}
        paragraph={`Compass, our analytical dashboard package, ensures that your school’s leadership and staff are 
        information rich. Utilizing the data from all of your core systems in one place allows you to see relationships 
        and trends, and reduces the time for critical, actionable insights.`}
        image={image}
        imageAlt={'Compass Logo'}
        pageName={'Compass'}
        pageSlogan={'Actionable Data & Dashboards'}
      />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <Footer />
    </main>
  );
}
