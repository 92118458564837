import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import HeadingWithParagraph from '../components/HeadingWithParagraph';
import ContactForm from '../components/sections/Contact/ContactForm';
import Newsletter from '../components/sections/Contact/Newsletter';
import FAQ from '../components/sections/Contact/FAQ';

export default function Contact() {
  return (
    <main>
      <NavBar />
      <HeadingWithParagraph
        heading={'Get In Touch'}
        paragraph="We'd love to hear from you! Please contact us using one of the methods listed below with any questions or for more information."
      />
      <ContactForm />
      <FAQ />
      <Newsletter />
      <Footer />
    </main>
  );
}
