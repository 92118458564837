import { useNavigate } from 'react-router-dom';
import Notification from '../Login/Notification';
import { useState, useEffect } from 'react';
import Spinner from '../Login/Spinner';

export default function Contact() {
  const navigate = useNavigate();
  const messageOne = 'Oops, something went wrong';
  const messageTwo =
    'We apologize, but it seems that your form submission was not successful. Please check to make sure all required fields are properly filled out and try again. ';
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  // variables to hold form data
  const [name, setName] = useState('');
  const [schoolDistrict, setSchoolDistrict] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [selection, setSelection] = useState('');
  const [message, setMessage] = useState('');

  // variables to hold form border colors
  const [nameBorder, setNameBorder] = useState('border-gray-300');
  const [schoolDistrictBorder, setSchoolDistrictBorder] =
    useState('border-gray-300');
  const [emailBorder, setEmailBorder] = useState('border-gray-300');
  const [phoneBorder, setPhoneBorder] = useState('border-gray-300');
  const [selectionText, setSelectionText] = useState('border-gray-300');
  const [messageBorder, setMessageBorder] = useState('border-gray-300');

  const handleChange = (event) => {
    setSelection(event.target.value);
  };

  const handlePhoneChange = (event) => {
    const newValue = event.target.value.replace(/[^0-9]/g, '');
    if (newValue.length <= 15) {
      setPhone(newValue);
    }
  };

  const validateForm = (e) => {
    e.preventDefault();
    if (name === '') {
      setNameBorder('border-red-500');
    } else {
      setNameBorder('border-gray-300');
    }
    if (schoolDistrict === '') {
      setSchoolDistrictBorder('border-red-500');
    } else {
      setSchoolDistrictBorder('border-gray-300');
    }
    if (email === '') {
      setEmailBorder('border-red-500');
    } else {
      setEmailBorder('border-gray-300');
    }
    if (phone === '' || phone.length < 8) {
      setPhoneBorder('border-red-500');
    } else {
      setPhoneBorder('border-gray-300');
    }
    if (selection === '') {
      setSelectionText('text-red-500');
    } else {
      setSelectionText('text-gray-500');
    }
    if (message === '') {
      setMessageBorder('border-red-500');
    } else {
      setMessageBorder('border-gray-300');
    }

    if (
      name === '' ||
      schoolDistrict === '' ||
      email === '' ||
      phone === '' ||
      phone.length < 8 ||
      selection === '' ||
      message === ''
    ) {
      setShow(true);
    } else {
      handleSubmit(e);
    }
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let response = await fetch(
        `${process.env.REACT_APP_URL}/support/inquiries/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: name,
            district: schoolDistrict,
            email: email,
            phone: phone,
            inquiry_type: selection,
            message: message,
          }),
        },
      );
      let data = await response.json();

      if (response.status === 201) {
        setLoading(false);
        navigate('/thank-you');
      } else {
        setLoading(false);
        setShow(true);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setShow(true);
    }
  };

  useEffect(() => {
    console.log(selection);
  }, [selection]);
  return (
    <div className='mt-12 overflow-hidden bg-white px-4 pb-10 sm:px-6 lg:px-8'>
      <div className='relative mx-auto max-w-xl'>
        <div>
          <form
            onSubmit={(e) => {
              validateForm(e);
            }}
            className='flex flex-col gap-y-6'
          >
            <div>
              <label htmlFor='full-name' className='sr-only'>
                Full name
              </label>
              <input
                type='text'
                name='full-name'
                id='full-name'
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoComplete='name'
                className={`${nameBorder} block w-full rounded-md py-3 px-4 placeholder-gray-500 shadow-sm focus:border-brandPrimary focus:ring-brandPrimary`}
                placeholder='Full name'
              />
            </div>
            <div>
              <label htmlFor='school-district' className='sr-only'>
                School district
              </label>
              <input
                type='text'
                name='school-district'
                id='school-district'
                value={schoolDistrict}
                onChange={(e) => setSchoolDistrict(e.target.value)}
                className={`${schoolDistrictBorder} block w-full rounded-md py-3 px-4 placeholder-gray-500 shadow-sm focus:border-brandPrimary focus:ring-brandPrimary`}
                placeholder='School district'
              />
            </div>
            <div>
              <label htmlFor='email' className='sr-only'>
                Email
              </label>
              <input
                id='email'
                name='email'
                type='email'
                autoComplete='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`${emailBorder} block w-full rounded-md py-3 px-4 placeholder-gray-500 shadow-sm focus:border-brandPrimary focus:ring-brandPrimary`}
                placeholder='Email'
              />
            </div>
            <div>
              <label htmlFor='phone' className='sr-only'>
                Phone
              </label>
              <input
                type='tel'
                name='phone'
                id='phone'
                autoComplete='tel'
                value={phone}
                onChange={handlePhoneChange}
                className={`${phoneBorder} block w-full rounded-md py-3 px-4 placeholder-gray-500 shadow-sm focus:border-brandPrimary focus:ring-brandPrimary`}
                placeholder='Phone'
              />
            </div>
            <fieldset className=''>
              <legend className={`${selectionText} block text-sm font-medium`}>
                I would like more information on:
              </legend>
              <div className='mt-4 grid grid-cols-1 gap-y-4'>
                <div className='flex items-center'>
                  <input
                    id='interactive-dashboards'
                    name='selection'
                    value='interactive-dashboards'
                    checked={selection === 'interactive-dashboards'}
                    onChange={handleChange}
                    type='radio'
                    className='h-4 w-4 border-gray-300 text-brandPrimary focus:ring-brandPrimary'
                  />
                  <label htmlFor='interactive-dashboards' className='ml-3'>
                    <span className='block text-sm text-brandText'>
                      Interactive Dashboards
                    </span>
                  </label>
                </div>
                <div className='flex items-center'>
                  <input
                    id='comprehensive-student-reports'
                    name='selection'
                    value='comprehensive-student-reports'
                    checked={selection === 'comprehensive-student-reports'}
                    onChange={handleChange}
                    type='radio'
                    className='h-4 w-4 border-gray-300 text-brandPrimary focus:ring-brandPrimary'
                  />
                  <label
                    htmlFor='comprehensive-student-reports'
                    className='ml-3'
                  >
                    <span className='block text-sm text-brandText'>
                      Comprehensive Student Reports
                    </span>
                  </label>
                </div>
                <div className='flex items-center'>
                  <input
                    id='mtss-management-system'
                    name='selection'
                    value='mtss-management-system'
                    checked={selection === 'mtss-management-system'}
                    onChange={handleChange}
                    type='radio'
                    className='h-4 w-4 border-gray-300 text-brandPrimary focus:ring-brandPrimary'
                  />
                  <label htmlFor='mtss-management-system' className='ml-3'>
                    <span className='block text-sm text-brandText'>
                      MTSS Management System
                    </span>
                  </label>
                </div>
                <div className='flex items-center'>
                  <input
                    id='grant-funding-for-polaris-education'
                    name='selection'
                    value='grant-funding-for-polaris-education'
                    checked={
                      selection === 'grant-funding-for-polaris-education'
                    }
                    onChange={handleChange}
                    type='radio'
                    className='h-4 w-4 border-gray-300 text-brandPrimary focus:ring-brandPrimary'
                  />
                  <label
                    htmlFor='grant-funding-for-polaris-education'
                    className='ml-3'
                  >
                    <span className='block text-sm text-brandText'>
                      Grant Funding for Polaris Education
                    </span>
                  </label>
                </div>
                <div className='flex items-center'>
                  <input
                    id='not-listed/custom'
                    name='selection'
                    value='not-listed/custom'
                    checked={selection === 'not-listed/custom'}
                    onChange={handleChange}
                    type='radio'
                    className='h-4 w-4 border-gray-300 text-brandPrimary focus:ring-brandPrimary'
                  />
                  <label htmlFor='not-listed/custom' className='ml-3'>
                    <span className='block text-sm text-brandText'>
                      Not Listed/Custom
                    </span>
                  </label>
                </div>
              </div>
            </fieldset>
            <div>
              <label htmlFor='message' className='sr-only'>
                Message
              </label>
              <textarea
                id='message'
                name='message'
                rows={4}
                className={`${messageBorder} block w-full rounded-md border py-3 px-4 placeholder-gray-500 shadow-sm focus:border-brandPrimary focus:ring-brandPrimary`}
                placeholder='Message'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <div className='sm:col-span-2'>
              <button
                type='submit'
                className='inline-flex w-full items-center justify-center rounded-md border border-transparent bg-brandPrimary px-6 py-3 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-110 hover:bg-brandPrimaryLight focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2'
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <Spinner loading={loading} />
      <Notification
        success={false}
        show={show}
        setShow={setShow}
        messageOne={messageOne}
        messageTwo={messageTwo}
      />
    </div>
  );
}
