import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import Resources from '../../components/sections/ResourcesList/Resources';
import Heading from '../../components/Heading';
import GrayCTA from '../../components/GrayCTA';

export default function MtssResources() {
  const resourceList = [
    {
      title: 'Best Practices in Multi-Tiered Support Structures',
      source: '- Hanover Research',
      summary:
        'A summary of best practices in MTSS from RtI structure, screening, interventions, and alignment to PBIS systems, Culturally Responsive Practices, and two school district profiles and both a district level and school level blueprint.',
      href: 'https://www.lwsd.org/uploaded/Website/Get_Involved/MTSS/Best_Practices_in_Multi-Tiered_Support_Structures.pdf',
      imageUrl:
        'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    },
    {
      title: 'Multi-Tiered System of Support Blueprint',
      source: '- Education, Massachusetts',
      summary:
        'A comprehensive guide to MTSS from learning about the steps and systems to create to implementing the plans through best practices.',
      href: 'https://www.doe.mass.edu/sfss/mtss/blueprint.pdf',
      imageUrl:
        'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    },
  ];
  return (
    <main>
      <NavBar />
      <Heading heading={'MTSS'} />
      <Resources resourceList={resourceList} />
      <GrayCTA />
      <Footer />
    </main>
  );
}
