import Footer from '../components/Footer';
import HeadingWithParagraph from '../components/HeadingWithParagraph';
import NavBar from '../components/NavBar';
import InfographicOne from '../components/sections/Funding/InfographicOne';
import Consultation from '../components/sections/Funding/Consultation';
import PageTopper from '../components/PageTopper';
import mobileImage from '../images/funding_hero_mobile.webp';

export default function About() {
  return (
    <main>
      <NavBar />
      <PageTopper
        imageUrl="bg-[url('images/funding_hero.webp')]"
        mobileImage={mobileImage}
        pageTitle={'Funding'}
        heading={'Maximize Your'}
        headingSpan={'Grant Money'}
        paragraph={
          "All Polaris Education products are eligible for grant funding! It is likely that you can acquire our product covered with grant funds so your budget doesn't change."
        }
      />
      <HeadingWithParagraph
        heading={'Get the Most out of Your Resources'}
        paragraph="Now, more than ever, schools are facing a large numbers of students in need. The pandemic has created learning loss and a host of other social-emotional problems for students that need attention.

        Thankfully, there are funds available to schools that allow them access to the tools that help students and promote their growth. Polaris Education's products are all eligible for grant funding."
      />
      <InfographicOne />
      <Consultation />
      <Footer />
    </main>
  );
}
