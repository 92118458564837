import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import Resources from '../../components/sections/ResourcesList/Resources';
import Heading from '../../components/Heading';
import GrayCTA from '../../components/GrayCTA';

export default function TrackingSuccessResources() {
  const resourceList = [
    {
      title: 'Measuring Success- The School Turnaround Field Guide',
      source: '- Wallace Foundation',
      summary:
        'A how to guide for turning around a failing school/district. The content focuses on a data informed and guided success track.',
      href: 'https://www.wallacefoundation.org/knowledge-center/pages/measuring-success-school-turnaround-field-guide.aspx',
      imageUrl:
        'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    },
    {
      title: 'Developing and Tracking Interventions',
      source: '- Network for College Success',
      summary:
        'Several tools for tracking the success of educational interventions.',
      href: 'https://ncs.uchicago.edu/tool-set/developing-and-tracking-interventions',
      imageUrl:
        'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    },
    {
      title: 'Developing and Tracking Interventions',
      source: '- NCS University of Chicago',
      summary:
        'Walks a data team through tracking the success of student programming. The article begins with creating a success team and walks the team through ways in which to plan, develop, track, and evaluate interventions.',
      href: 'https://ncs.uchicago.edu/sites/ncs.uchicago.edu/files/uploads/toolsets/NCS_FOT_Toolkit_URAD_SetC_0.pdf',
      imageUrl:
        'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    },
  ];
  return (
    <main>
      <NavBar />
      <Heading heading={'Track Success of Programming'} />
      <Resources resourceList={resourceList} />
      <GrayCTA />
      <Footer />
    </main>
  );
}
