import Footer from '../../components/Footer';
import GrayCTA from '../../components/GrayCTA';
import NavBar from '../../components/NavBar';
import PostEight from '../../components/sections/BlogPost/PostEight';

export default function BlogPostTwo() {
  return (
    <main>
      <NavBar />
      <PostEight />
      <GrayCTA margins={'mt-16 lg:mt-32'} />
      <Footer />
    </main>
  );
}
