import { useState } from 'react';
import Notification from '../Login/Notification';

export default function PasswordResetForm() {
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  let distUrl = '';

  let resetPassword = async (e) => {
    e.preventDefault();
    let response = await fetch(
      `${process.env.REACT_APP_URL}/support/district-url/?username=${e.target.username.value}`,
      {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    let data = await response.json();

    if (response.status === 200) {
      distUrl =
        data.district_url +
        'login/' +
        encodeURIComponent(e.target.username.value.replace(/\./g, '%2E'));
      /* Second fetch starts here */
      e.preventDefault();
      let response = await fetch(
        `${distUrl}/core/password_reset/?username=${e.target.username.value}`,
        {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      /* let data2 = await response.json(); */

      if (response.status === 200) {
        setShowSuccess(true);
      } else {
        setShowError(true);
      }
      /* Second fetch ends here */
    } else {
      setShowError(true);
    }
  };

  return (
    <section className=''>
      <div className='mt-12 bg-white px-4 sm:px-6 lg:px-8'>
        <div className='mx-auto max-w-4xl'>
          <form className='' onSubmit={resetPassword}>
            <div>
              <label htmlFor='email address' className='sr-only'>
                Email address
              </label>
              <input
                type='text'
                name='username'
                id='username'
                autoComplete='email'
                className='block w-full rounded-md border-gray-300 py-3 px-4 placeholder-brandText shadow-sm focus:border-brandPrimary focus:ring-brandPrimary'
                placeholder='Email address'
              />
            </div>
            <div>
              <button
                type='submit'
                className='mt-6 inline-flex justify-center rounded-md border border-transparent bg-brandPrimary py-3 px-6 text-base font-medium text-white shadow-sm hover:bg-brandPrimaryLight focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2'
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <Notification
        success={false}
        show={showError}
        setShow={setShowError}
        messageOne={'Error!'}
        messageTwo={
          'Sorry, we were unable to complete your request. Please try again or contact support.'
        }
      />
      <Notification
        success={true}
        show={showSuccess}
        setShow={setShowSuccess}
        messageOne={'Success!'}
        messageTwo={
          'Please check your email for a link to reset your password.'
        }
      />
    </section>
  );
}
