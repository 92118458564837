import { Link } from 'react-router-dom';
import facebookIcon from '../images/social/facebook.svg';
import linkedinIcon from '../images/social/linkedin.svg';

const navigation = {
  solutions: [
    { name: 'Integral', href: '/integral' },
    { name: 'Insight', href: '/insight' },
    { name: 'Lighthouse', href: '/lighthouse' },
    { name: 'Compass', href: '/compass' },
    { name: 'Adviser', href: '/adviser' },
    { name: 'Adapt', href: '/adapt' },
  ],

  company: [
    { name: 'About', href: '/about' },
    { name: 'Resources', href: '/resources' },
    { name: 'Blog', href: '/blog' },
    { name: 'Funding', href: '/funding' },
  ],

  support: [
    { name: 'Contact', href: '/contact' },
    /* { name: 'Password Reset', href: '/auth/password-reset' }, */
  ],

  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/PolarisEduApp',
      icon: facebookIcon,
    },
    {
      name: 'Linkedin',
      href: 'https://www.linkedin.com/company/polaris-education-llc',
      icon: linkedinIcon,
    },
  ],
};

export default function Footer() {
  return (
    <footer className='bg-white' aria-labelledby='footer-heading'>
      <h2 id='footer-heading' className='sr-only'>
        Footer
      </h2>
      {/* This is the div in that contains everything above the divider */}
      <div className='mx-auto max-w-8xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8'>
        <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4'>
          <div className=''>
            <h3 className='text-sm font-semibold uppercase tracking-wider text-brandText'>
              Solutions
            </h3>
            <ul className='mt-4 space-y-4'>
              {navigation.solutions.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    className='text-base text-brandText hover:text-gray-700'
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className='mt-12 lg:mt-0'>
            <h3 className='text-sm font-semibold uppercase tracking-wider text-brandText'>
              Company
            </h3>
            <ul className='mt-4 space-y-4'>
              {navigation.company.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    className='text-base text-brandText hover:text-gray-700'
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className='mt-12 lg:col-span-2 xl:col-span-1 xl:mt-0'>
            <h3 className='text-sm font-semibold uppercase tracking-wider text-brandText'>
              Support
            </h3>
            <ul className='mt-4 space-y-4'>
              {navigation.support.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    className='text-base text-brandText hover:text-gray-700'
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className='mt-8 xl:mt-0'>
            <h3 className='text-sm font-semibold uppercase tracking-wider text-brandText'>
              Subscribe to our newsletter
            </h3>
            <p className='mt-4 text-base text-brandText'>
              The latest news, articles, and resources, sent to your inbox.
            </p>
            <form className='mt-4 sm:flex sm:max-w-md'>
              <label htmlFor='email-address' className='sr-only'>
                Email address
              </label>
              <input
                type='email'
                name='email-address'
                id='email-address'
                autoComplete='email'
                required
                className='w-full min-w-0 appearance-none rounded-md border border-gray-300 bg-white py-2 px-4 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-brandPrimary focus:placeholder-gray-400 focus:outline-none focus:ring-brandPrimary'
                placeholder='Enter your email'
              />
              <div className='mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0'>
                <button
                  type='submit'
                  className='flex w-full items-center justify-center rounded-md border border-transparent bg-brandPrimary py-2 px-4 text-base font-medium text-white duration-300 ease-in-out hover:scale-110 hover:bg-brandPrimaryLight focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2'
                >
                  Subscribe
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className='mt-8 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between'>
          <div className='flex space-x-6 md:order-2'>
            {navigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                target='_blank'
                rel='noreferrer'
                className='text-brandText hover:text-brandText'
              >
                <span className='sr-only'>{item.name}</span>
                <img
                  className='h-6 w-6'
                  src={item.icon}
                  alt={`${item.name} icon`}
                />
              </a>
            ))}
          </div>
          <p className='mt-8 text-base text-brandText md:order-1 md:mt-0'>
            &copy; {new Date().getFullYear()} Polaris Education. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
