import infographic from '../../../images/about_infographic_1.webp';

export default function InfographicOne() {
  return (
    <section className='mx-auto mt-16 bg-gray-100 lg:mt-32'>
      <div className='mx-auto flex max-w-8xl flex-col items-center justify-center py-10 px-4 sm:px-6 lg:flex-row lg:justify-evenly lg:px-8'>
        <div className='mx-auto flex flex-col justify-center lg:max-w-[450px]'>
          <p className='mx-auto text-center text-xl font-medium text-brandText sm:text-3xl sm:font-normal md:mt-5 md:max-w-3xl lg:max-w-md'>
            Polaris combines data from a multitude of places, not just the SIS,
            and allows us to leverage these data sources to produce powerful
            information about individual students as well as groups and cohorts.
            <br /> <br />
            <span className='text-brandPrimary'>
              It helps us create a single, sharable source of truth for looking
              at all data.
            </span>
          </p>
        </div>
        <div className='mx-6 mt-10 flex max-w-[450px] sm:mx-auto md:mb-5 lg:mt-0'>
          <img src={infographic} alt='infographic of a computer with data' />
        </div>
      </div>
    </section>
  );
}
