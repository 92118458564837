import squareLogo from '../images/logos/square_logo.png';
import { Link } from 'react-router-dom';

export default function GrayCTA({ margins }) {
  return (
    <div
      className={`flex items-center justify-center bg-gray-100 px-4 sm:px-6 lg:px-8 ${margins}`}
    >
      <div className='mr-2 hidden h-14 w-14 sm:mr-8 sm:flex sm:h-20 sm:w-20 md:h-32 md:w-32'>
        <img src={squareLogo} alt='' />
      </div>
      <div className='my-16'>
        <h2 className='text-center text-3xl font-extrabold tracking-tight text-brandText'>
          Ready to give Polaris a try?
        </h2>
        <div className='flex justify-center'>
          <Link
            className='mx-auto mt-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border-2 border-transparent bg-brandPrimary px-5 py-3 text-lg font-bold text-white shadow-sm duration-300 ease-in-out hover:scale-110 hover:bg-brandPrimaryLight'
            to='/contact'
          >
            Request a Demo
          </Link>
        </div>
      </div>
      <div className='ml-2 hidden h-14 w-14 sm:ml-8 sm:flex sm:h-20 sm:w-20 md:h-32 md:w-32'></div>
    </div>
  );
}
