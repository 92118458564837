import divider from '../../../images/dividers/about_divider.png';
import DividerWithHeading from '../../DividerWithHeading';
import infographic from '../../../images/about_infographic_2.webp';

export default function InfographicTwo() {
  return (
    <section className='mx-auto mt-16 max-w-8xl px-4 sm:px-6 lg:mt-32 lg:px-8'>
      <DividerWithHeading
        image={divider}
        heading={'Why Polaris?'}
        paragraph={
          'The main benefit is actionable insight– information that allows us to do something to help students.  Our solution leads to action by providing tools to collect to collect, store, and analyze the most relevant data.'
        }
      />
      <div className='mx-auto flex w-full flex-col-reverse items-center justify-center py-12 lg:flex-row lg:justify-evenly'>
        <div className='mx-6 mt-10 flex lg:mt-0 lg:max-w-[500px]'>
          <img src={infographic} alt='infographic of a man on stairs' />
        </div>
        <div className='flex flex-col lg:max-w-[500px]'>
          <p className='mx-auto text-center text-2xl text-brandPrimary md:mt-5 md:max-w-3xl lg:text-3xl'>
            Polaris can be used to manage MTSS and to collect data for state
            reporting that is not covered by most student information systems.
          </p>
        </div>
      </div>
      <p className='mx-auto max-w-prose text-center text-lg text-brandText lg:text-xl'>
        Check out the rest of the Knowledge Base to learn more about Polaris.
        There are sections on the different functions available. If you need
        further assistance or would like us to create something that isn't
        already here, please hit the contact link below and let us know!
      </p>
    </section>
  );
}
