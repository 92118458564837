import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import Resources from '../../components/sections/ResourcesList/Resources';
import Heading from '../../components/Heading';
import GrayCTA from '../../components/GrayCTA';

export default function CommunicatingDataResources() {
  const resourceList = [
    {
      title:
        'Tips for Administrators, Teachers, and Families: How to Share Data Effectively ',
      source: '– Harvard Family Research Project',
      summary:
        'As you discuss with your team how to share relevant data with your school community stakeholders, this is essential reading which includes seven tips for completing your communication objectives.',
      href: 'https://archive.globalfrp.org/var/hfrp/storage/fckeditor/File/7-DataSharingTipSheets-HarvardFamilyResearchProject.pdf',
      imageUrl:
        'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    },
    {
      title: 'How to Share Your Student Data with Colleagues',
      source: '– Jotform',
      summary:
        'Sharing data, especially those that may be negatively viewed, can be a daunting task. This article shares information on having those courageous conversations with your staff and stakeholders.',
      href: 'https://www.jotform.com/blog/how-to-share-your-student-data-with-colleagues/',
      imageUrl:
        'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    },
    {
      title: 'Five (good) Ways to Talk About Data ',
      source: '– ASCD',
      summary:
        'This article proposes five components to any good data conversations. Most importantly, the students come first and everyone in the organization needs to be reminded that the future courageous conversations, and data, are for the improvement of student learning and achievement.',
      href: 'https://www.ascd.org/el/articles/five-(good)-ways-to-talk-about-data',
      imageUrl:
        'https://images.unsplash.com/photo-1524178232363-1fb2b075b655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=700&q=80',
    },
  ];
  return (
    <main>
      <NavBar />
      <Heading heading={'Communicating Data and Goals with Staff'} />
      <Resources resourceList={resourceList} />
      <GrayCTA />
      <Footer />
    </main>
  );
}
