import image from '../../../../images/service_pages/lighthouse/growth_and_success.webp';
import dividerDesktop from '../../../../images/dividers/lighthouse_monitor_progress_bottom_desktop.svg';
import dividerMobile from '../../../../images/dividers/lighthouse_monitor_progress_bottom_mobile.svg';

export default function ImageRightTextLeft() {
  return (
    <section className={'mx-auto bg-white py-20 lg:py-32'}>
      <div className='mx-auto px-4 sm:px-6 lg:max-w-8xl lg:px-8'>
        <div className='mx-auto max-w-8xl md:hidden'>
          <img src={dividerMobile} alt='Divider with Lighthouse logo' />
        </div>
        <div className='mx-auto hidden max-w-8xl md:block'>
          <img src={dividerDesktop} alt='Divider with Lighthouse logo' />
        </div>
        <div className='mt-4 mb-6 sm:mt-6 md:mb-12 lg:mb-16'>
          <h2 className='mx-auto max-w-xl text-center text-3xl font-extrabold tracking-tight text-brandText md:text-4xl lg:text-5xl'>
            Growth & Success
          </h2>
        </div>
        <div
          className={'flex flex-col items-center justify-between lg:flex-row'}
        >
          <div className='mb-10 max-w-prose text-center text-lg text-brandText lg:mb-0 lg:w-1/2 lg:max-w-2xl lg:text-xl'>
            <p className={''}>
              With all of your resources aligned and focused, you can expect to
              see the results in the impact your school has on
              students–improvements in learning, increasing assessment scores,
              and a reduction in failures.  With the ability to look at the
              entire intervention or get down to detail on individual students,
              you will always be in a position to evaluate your progress and
              make the changes needed to help your school.
            </p>
          </div>
          <div className={'max-w-xl lg:ml-10 lg:w-1/2 lg:max-w-none'}>
            <img
              src={image}
              className={'max-h-[525px] rounded-2xl shadow-xl'}
              alt={'Action details page'}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
