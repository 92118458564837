import pcInfographicImage from '../../../../images/service_pages/insight/pc_infographic.png';
import insightDividerDesktop from '../../../../images/dividers/insight_divider_desktop.svg';
import insightDividerMobile from '../../../../images/dividers/insight_divider_mobile.svg';

export default function PcInfographic() {
  return (
    <section className='mx-auto mt-16 mb-0 text-center lg:mt-32'>
      <div className='mx-auto max-w-8xl px-4 sm:px-6 lg:px-8'>
        <div className='mb-12'>
          <h2 className='mx-auto max-w-5xl text-center text-3xl font-extrabold tracking-tight text-brandPrimary md:text-4xl lg:text-5xl'>
            Student-at-a-Glance Report:
          </h2>
        </div>
        <div className='mx-auto sm:px-5 md:px-10 lg:max-w-4xl lg:px-0 xl:max-w-5xl'>
          <img
            src={pcInfographicImage}
            alt='Infographic of Polaris page showing visualized data'
          />
        </div>
        <div className='mx-auto mt-12 md:hidden'>
          <img src={insightDividerMobile} alt='Divider icon' />
        </div>
        <div className='mx-auto mt-16 hidden md:block lg:mt-32'>
          <img src={insightDividerDesktop} alt='Divider icon' />
        </div>
      </div>
    </section>
  );
}
