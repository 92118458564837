import divider from '../../../images/dividers/home_solutions_divider.png';
import infographic from '../../../images/home_infographic.webp';
import DividerWithHeading from '../../DividerWithHeading';

export default function Infographic() {
  return (
    <section className='mx-auto max-w-8xl px-4 sm:px-6 lg:px-8'>
      <DividerWithHeading
        image={divider}
        heading={'Our Solution'}
        paragraph={
          'View our detailed pathway from "start" to "finish" with improved results using the app.'
        }
      />
      <div className='mt-10 flex w-full items-center justify-center'>
        <div className='lg:px-16'>
          <img src={infographic} alt='' />
        </div>
      </div>
    </section>
  );
}
