import HTMLFlipBook from 'react-pageflip';
import React, { useRef } from 'react';
import bookCover from '../../images/book_pages/cover.jpg';
import page1 from '../../images/book_pages/1.jpg';
import page2 from '../../images/book_pages/2.jpg';
import page3 from '../../images/book_pages/3.jpg';
import page4 from '../../images/book_pages/4.jpg';
import page5 from '../../images/book_pages/5.jpg';
import page6 from '../../images/book_pages/6.jpg';
import page7 from '../../images/book_pages/7.jpg';
import page8 from '../../images/book_pages/8.jpg';
import page9 from '../../images/book_pages/9.jpg';
import page10 from '../../images/book_pages/10.jpg';
import page11 from '../../images/book_pages/11.jpg';
import page12 from '../../images/book_pages/12.jpg';
import page13 from '../../images/book_pages/13.jpg';
import page14 from '../../images/book_pages/14.jpg';
import page15 from '../../images/book_pages/15.jpg';
import page16 from '../../images/book_pages/16.jpg';
import page17 from '../../images/book_pages/17.jpg';
import page18 from '../../images/book_pages/18.jpg';
import page19 from '../../images/book_pages/19.jpg';

const Page = React.forwardRef((props, ref) => {
  return (
    <div className='' ref={ref}>
      <img
        className='border border-slate-200 shadow-lg shadow-slate-200'
        src={props.image}
        alt=''
      />
    </div>
  );
});

function MyBook(props) {
  return (
    <section className='mx-auto mt-10 flex max-h-screen max-w-8xl flex-col items-center justify-center px-4 pb-16 sm:px-6 lg:px-8'>
      <HTMLFlipBook
        width={300}
        height={375}
        showCover={true}
        size={'stretch'}
        maxShadowOpacity={0.1}
      >
        <Page image={bookCover} />
        <Page image={page1} />
        <Page image={page2} />
        <Page image={page3} />
        <Page image={page4} />
        <Page image={page5} />
        <Page image={page6} />
        <Page image={page7} />
        <Page image={page8} />
        <Page image={page9} />
        <Page image={page10} />
        <Page image={page11} />
        <Page image={page12} />
        <Page image={page13} />
        <Page image={page14} />
        <Page image={page15} />
        <Page image={page16} />
        <Page image={page17} />
        <Page image={page18} />
        <Page image={page19} />
      </HTMLFlipBook>
    </section>
  );
}

export default MyBook;
